@import './css/vars.styl'

.footer
  general-container-outer()
  padding 3.5rem 0
  background var(--color-footer-background)
  --color-text var(--color-footer-text)

  &__inner
    general-container-inner()
    display flex
    flex-direction column
    align-items center
    gap 1.5rem

  &__icons
    p
      display flex
      justify-content center
      gap 1rem


.sticky-link
  position fixed
  z-index 2
  top 50%
  right 0
  display flex
  align-items center
  border-top-left-radius 10rem
  border-bottom-left-radius 10rem
  overflow hidden
  transform translate(calc(100% - 65px), -50%)
  background #ffffff00

  @media (max-width: 600px)
    transform translate(calc(100% - 55px), -50%)
    top auto
    bottom 10%

  &--inited
    transition transform 0.25s ease, background 0.15s ease

  &:hover,
  &:focus
    transform translate(0, -50%)

  img
    border-radius 10rem
    overflow hidden
    height 65px
    width 65px

    @media (max-width: 600px)
      height 55px
      width 55px

  &__text
    padding 0 1.6rem 0 1.3rem
    font-weight 700

  &--privat
    &:hover
      background var(--color-dropdown-background)
    img
      padding 0.2rem

  &--business
    &:hover
      background var(--color-purple-700)
    img
      background var(--color-purple-500)
