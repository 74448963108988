.component-buttons
  .component-image-list + &,
  .component-free-text + &
    padding-top 1rem

  &__inner
    display flex
    flex-wrap wrap
    justify-content var(--justify-content)

    @media (max-width: 700px)
      justify-content center

y-padding = 10px

general-button()
  text-decoration none
  font-family inherit
  font-weight 700
  margin 0 1rem 1rem 0
  cursor pointer
  min-height 'calc(var(--dimensions-button-height) - %s)' % (y-padding * 2)
  display inline-flex
  justify-content center
  align-items center

general-button--button()
  padding y-padding 2rem
  border-radius 5px
  @media (max-width 1200px)
    padding-left 1.6rem
    padding-right 1.6rem
  @media (max-width 1000px)
    padding-left 1.4rem
    padding-right 1.4rem
  @media (max-width 700px)
    padding-left 1.2rem
    padding-right 1.2rem

general-button--primary()
  background var(--color-button-background-primary)
  color var(--color-button-text-primary)
  border var(--border-button-primary)

  &:hover
    background var(--color-button-background-primary-hover)
    color var(--color-button-text-primary-hover)

general-button--secondary()
  background var(--color-button-background-secondary)
  color var(--color-button-text-secondary)
  border var(--border-button-secondary)

  &:hover
    background var(--color-button-background-secondary-hover)
    color var(--color-button-text-secondary-hover)

general-button--tertiary()
  background var(--color-button-background-tertiary)
  color var(--color-button-text-tertiary)
  border var(--border-button-tertiary)

  &:hover
    background var(--color-button-background-tertiary-hover)
    color var(--color-button-text-tertiary-hover)


.component-button
  general-button()

  &--button
    general-button--button()

  &--button&--primary
    general-button--primary()

  &--button&--secondary
    general-button--secondary()

  &--button&--tertiary
    general-button--tertiary()

  &--link
    color var(--color-text)
    white-space nowrap

    &:after
      content " "
      margin-left 1rem
      border-right 2px solid var(--color-text)
      border-top 2px solid var(--color-text)
      height 10px
      width 10px
      transform rotate(45deg)

  &--link&--primary:hover
    color var(--color-button-background-primary)

  &--link&--secondary:hover
    color var(--color-button-background-secondary)


// Ninja forms...
nf-cells nf-field input[type="button"].ninja-forms-field.nf-element
  general-button()
  general-button--button()
  general-button--secondary()

.privat-foretag-choice__button
  general-button()
  general-button--button()
  general-button--primary()
  margin-right 0
  margin-top 1rem
