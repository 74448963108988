@import './css/vars.styl'

.header
  background var(--color-white)
  // box-shadow: 0 0 5px #00000077
  position relative
  z-index 2

  // Top menu START
  &__top-menu
    background var(--color-primary-accent)
    color var(--color-white)
    font-weight 600
    general-container-outer()

    &-inner
      general-container-inner()
      display flex
      justify-content space-between
      align-items center

    @media (max-width: 1000px)
      font-size 1rem

    & > div
      display flex

    .menu
      display flex
      height 43px
      overflow hidden

      @media (max-width: 1000px)
        height 36px

      @media (max-width: 600px)
        height 32px

      .menu-item
        margin-right 5rem
        position relative
        display flex
        align-items center

        @media (max-width: 1000px)
          margin-right 3rem

        @media (max-width: 600px)
          margin-right 2rem

        &.current-menu-item,
        &.current-page-ancestor
          &:after
            content " "
            height 30px
            width 30px
            background-color var(--color-white)
            position absolute
            left 50%
            top 100%
            transform translate(-50%, -0.1rem) rotate(45deg)

            @media (max-width: 1000px)
              top 105%

            @media (max-width: 600px)
              top 110%

  // Top menu END

  &__top-menu-image
    max-height 43px
    width auto
    display block
    transform translateX(4px)

    @media (max-width: 1000px)
      max-height 35px

    @media (max-width: 600px)
      display: none

  &__logo
    display block
    height 65px
    width auto

    @media (max-width: 1500px)
      height 60px
    @media (max-width: 1350px)
      height 58px
    @media (max-width: 1200px)
      height 53px
    @media (max-width: 1000px)
      height 50px !important
    @media (max-width: mobile-breakpoint)
      height 53px !important
    @media (max-width: 600px)
      height 40px !important

  &__second-row
    general-container-outer()

  &__second-row-inner
    general-container-inner()
    display flex
    align-items center
    height 105px

    @media (max-width: 1000px)
      height 90px

    @media (max-width: 600px)
      height 70px

  &__navs
    flex 1
    display flex
    justify-content flex-end
    flex-wrap wrap

    .current-menu-item,
    .current_page_ancestor
      & > a
        text-decoration underline
        text-decoration-color var(--color-primary-accent)
        text-underline-position under

  &__nav-menu-primary
    padding 0 0 0.9rem
    font-weight 600

    .menu-item
      @media (min-width: mobile-breakpoint)
        padding-bottom 0.5rem

  &__nav-menu-secondary
    color var(--color-primary-accent)
    padding 0.5rem 0 0.45rem
    display flex
    justify-content flex-end
    align-items center
    gap 1rem
    font-weight 400
    font-size 0.9em

    .current-menu-item
      text-decoration-color var(--color-gray-100)

    @media (max-width: mobile-breakpoint)
      border-bottom 1px solid var(--color-gray-800)
      flex-direction column
      align-items flex-start

    // This is the actual menu inside!
    & > div
      @media (max-width: mobile-breakpoint)
        width 100%
        border-top 1px solid

  &__nav-menu-secondary,
  &__nav-menu-primary
    width 100%

    @media (max-width: mobile-breakpoint)
      padding 0

    .menu
      display flex
      justify-content flex-end
      position relative

      @media (max-width: mobile-breakpoint)
        flex-direction column

      .sub-menu
        display none
        font-weight 400

        @media (min-width: mobile-breakpoint)
          position absolute
          flex-direction column
          background var(--color-white)
          right 0
          top 100%
          box-shadow 0 3px 6px rgba(black, 0.15)
          padding 1.3rem 1rem 0.8rem

          .menu-item
            white-space nowrap
            display flex
            margin 0

            a
              padding 0.2rem

        @media (max-width: mobile-breakpoint)
          display block
          border-top 1px solid var(--color-gray-800)

          .menu-item a
            padding-left 2rem
            color var(--color-gray-500)

      .menu-item
        margin-left 3rem

        @media (min-width: mobile-breakpoint)
          &:hover .sub-menu
            display flex

        @media (max-width: 1500px)
          margin-left 2.4rem
        @media (max-width: 1350px)
          margin-left 1.3rem
        @media (max-width: 1000px)
          margin-left 1.5rem
        @media (max-width: mobile-breakpoint)
          margin-left 0

          &:first-child a
            border-top none

          a
            padding 1rem 3rem 1rem 1rem
            display block
            border-top 1px solid var(--color-gray-800)
            min-width 170px

            &:hover
              background-color var(--color-button-background-primary-hover)
              color var(--color-white)

      .menu-item-has-children
        position relative

        @media (min-width: mobile-breakpoint)
          padding-right 25px
          &:after
            content " "
            background url("./images/icon-sub-menu.svg") no-repeat center right
            background-size cover
            height 22px
            width 25px
            position absolute
            right 0
            top 0
            transition transform 0.1s ease

          &:hover:after
            transform rotate(180deg)

  &__checkbox-mobile-menu,
  &__checkbox-mobile-menu-label
    display none

  @media (max-width: mobile-breakpoint)
    &__navs-wrapper
      display none
      position absolute
      background var(--color-white)
      border-radius var(--border-radius-s)
      box-shadow 0 2px 6px #00000022
      transform translate(0.6rem, -1rem)
      overflow hidden

    &__checkbox-mobile-menu-label
      display block
      position relative
      z-index 1

      .header__checkbox-mobile-menu-label--menu-visible
        display none

      img
        height 32px
        width auto
        cursor pointer
        display block

        @media (max-width: 700px)
          height 28px

        @media (max-width: 500px)
          height 24px

    &__checkbox-mobile-menu:checked ~ &__checkbox-mobile-menu-label
      .header__checkbox-mobile-menu-label--menu-visible
        display block
      .header__checkbox-mobile-menu-label--menu-hidden
        display none

    &__checkbox-mobile-menu:checked ~ &__navs-wrapper
      display block

  // Search stuff
  &__search
    &, .searchform
      display flex
      align-items center
      gap 1rem

  &__search
    display none


  &__search-toggle
    height 32px
    width 32px
    background url("./images/seach-icon.svg") no-repeat
    background-size cover
    display block
    cursor pointer

    .body--privat &
      background url("./images/seach-icon--ssf.svg") no-repeat

    @media (max-width: mobile-breakpoint)
      order -1
      margin 1rem
      margin-bottom 0

    &--close
      background url("./images/close-seach-icon.svg") no-repeat
      order 10

  &__checkbox-search
    display none

  &__checkbox-search:checked ~ &__search-toggle
  &__checkbox-search:checked + div // this is the menu!
    display none

  &__checkbox-search:checked ~ &__search
    display flex

.id-dropdown
  font-size: 0.8rem
  font-weight: normal
  padding: 0 0.65rem
  position relative
  background: var(--color-dropdown-background)
  color: var(--color-dropdown-text)
  transition: background 0.1s ease
  min-width: 185px

  &--open, &:hover
    background: var(--color-dropdown-background-hover)

  &__top-bar
    display: flex
    justify-content: center
    align-items: center
    gap: 0.8rem
    cursor: pointer
    white-space: nowrap

  svg
    width: 19px
    height: auto
    transform: translate(2px, -1px) 

  &__caret-icon-wrapper svg
    width: 14px
    color: var(--color-dropdown-text)
    margin-left: 0.1rem
    margin-right: 0.4rem
    transform: rotate(0deg)
    transition: transform 0.1s ease

  &--open &__caret-icon-wrapper svg
    transform: rotate(180deg)

  &__fold-out
    display: none
    position absolute
    top: 100%
    background: white
    z-index 35
    color: black;
    box-shadow: 0 1px 10px rgba(0,0,0,0.1)
    left: 0
    width: 100%
    padding: 0.9rem 1rem
    flex-direction: column

  &--open &__fold-out
    display: flex

  &__hl
    font-weight: 600
    padding-bottom: 0.5rem
    font-size: 0.85rem

  .id-input
    width: 20px
    height: 34px
    border-radius: 3px
    background: var(--color-gray-900)
    padding: 0
    outline: none
    border: 1px solid var(--color-gray-700)
    text-align: center

  .id-input:disabled
    opacity: 0.5

  &__inputs
    display: flex
    gap: 0.6rem
    align-items: center
    padding: 0 0 1.3rem

  &__info
    line-height: 1.5

  &__info svg
    width: 18px
    height: auto
    margin-right: 0.3rem
    path
      fill: var(--color-primary-accent)

  &__info_hl
    display: flex
    align-items: center
    font-weight: 600
    padding: 0 0 0.2rem


// Loading indicator...
.lds-ellipsis {
  display: none;
  position: relative;
  width: 80px;
  height: 27px;
  margin: 1px auto 0;
}
.lds-ellipsis--active {
  display: block;
}
.lds-ellipsis div {
  position: absolute;
  top: 0;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: var(--color-primary-accent);
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}
