.privat-foretag-choice
  display: flex
  // This was due to header...
  height: calc(100vh - 43px)
  max-height: 950px
  // min-height: "min(950px, 100vh)" % null
  position relative

  @media (max-width 815px)
    flex-direction: column

  &__column
    background: var(--ssf-light-blue)
    flex: 1
    display: flex
    justify-content: center
    align-items: center
    position relative

    & + &
      background: var(--color-primary-accent)

      & *
        color: var(--color-white)

      @media (max-width 815px)
        padding-top: 3rem

  &__column-inner
    padding: 1rem 2rem
    display: flex
    flex-direction: column
    align-items: center
    justify-content: center
    max-width: 645px
    padding-bottom: 3rem

    @media (max-width 815px)
      min-height: 50vh

    @media (max-width 600px)
      min-height: 60vh
      padding-bottom: 4rem


  &__center-image
    position absolute
    top 25%
    left 50%
    transform translate(-50%, -50%)

    @media (max-width 815px)
      top 50%

    img
      width: 165px
      height: 180px
      object-fit: contain
      object-position: center

      @media (max-width 815px)
        width: 150px
        height: 150px

  &__logo
    img
      width: 230px
      height: 100px
      object-fit: contain
      object-position: center
      position: absolute
      bottom: 4rem
      left: 50%
      transform: translateX(-50%)

      @media (max-width 815px)
        width: 150px
        height: 80px
        bottom: 3rem
