@import '../../css/vars.styl'

.component-brand-assets
  general-container-outer()
  padding 3.5rem 0

  &__inner
    general-container-inner()
    colored-links()

  &__content-above
    padding 0 0 1.5rem

  &__posts
    display flex
    flex-wrap wrap
    gap 2rem
    padding 0 0 2rem

  &__post
    padding 0 0 1rem
    flex 1
    min-width 26%
    max-width 33%

    @media (max-width: 800px)
      min-width 35%
      max-width 50%

    h2 a
      color var(--color-text)
      &:hover
        color var(--color-link)

    img 
      width 100%
      height 200px
      object-fit scale-down
      margin 0 0 1.3rem
      background-color var(--color-gray-900)

      @media (max-width: 1200px)
        height 180px
      @media (max-width: 1000px)
        height 170px
      @media (max-width: 800px)
        height 160px
      @media (max-width: 600px)
        height 140px

    