@import './vars.styl'

html
  font-size 16px
  @media (max-width 1200px)
    font-size 15px
  @media (max-width 1000px)
    font-size 14px
  @media (max-width 700px)
    font-size 13px

body
  font-family 'Dagny', sans-serif
  line-height 1.375
  color var(--color-gray-100)

h1, h2, h3
  font-weight 700
  line-height 1.23
  color var(--color-text-headline)

h1
  padding 0 0 1.3rem
  font-size var(--font-size-h1)
  max-width 35ch

h2
  padding 0 0 1rem
  font-size var(--font-size-h2)
  max-width 40ch

h3
  padding 0 0 0.7rem
  font-size var(--font-size-h3)
  
h4
  padding 0 0 0.8rem
  font-size var(--font-size-h4)

p
  font-size 1rem
  padding 0 0 1rem
  color var(--color-text)

a
  text-decoration none
  color inherit
  &:hover
    color var(--color-text)

strong
  font-weight 600

img.aligncenter
  display block
  margin 0 auto

article 
  ol,
  ul
    padding 0.5rem
    padding-left 2.5rem
    color var(--color-text)

    li
      padding 0 0 0.5rem 0.5rem

  ul li
    list-style disc

  ol li
    list-style decimal

// Input stuff
// Search input box
#searchsubmit,
#s
  margin 0
  height 32px
  padding-top 0
  padding-bottom 0
  font-family inherit

#s
  border-radius 2rem
  border 1px solid var(--color-gray-600)
  min-width 300px
  margin-right 1rem
  padding-left 1rem
  @media (max-width: mobile-breakpoint)
    min-width 0