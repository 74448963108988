@import '../../css/vars.styl'

.component-image
  &__inner
    display flex
    flex-wrap wrap
    justify-content var(--justify-content)
    
    @media (max-width: 700px)
      justify-content center

    img
      max-width 100%
      height auto

      @media (max-width: mobile-breakpoint)
        margin-bottom 1rem

      &.component-image__image--native-size
        @media (max-width: mobile-breakpoint)
          max-width 95%
        
      &.component-image__image--context-size
        width 73%
        height auto
        p_max_width()

        @media (max-width: mobile-breakpoint)
          width 95%

  & + .component-free-text,
  .component-free-text + &
    margin 2.5rem 0 0