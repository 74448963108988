.component-image-list
  &__content
    padding 0 0 2rem

  &__images
    display flex
    justify-content space-around
    gap 3.5rem
    flex-wrap wrap
    counter-reset image-list-counter

    @media (max-width 500px)
      gap 2.5rem 1.5rem

  &__image
    display flex
    flex-direction column
    align-items center
    flex 1
    max-width 25%
    min-width 150px

    @media (max-width 700px)
      max-width none
      min-width 40%

    @media (max-width 500px)
      max-width 35%
      min-width 30%

    img
      margin 0 0 1rem
      max-width 100%
      max-height 300px

  &__image-wrapper
    position relative

    @media (max-width 500px)
      max-width 80%

    .component-image-list__images--numbered &:before
      content counter(image-list-counter)
      counter-increment image-list-counter
      position absolute
      // TODO(kristoffer): Use some kind of "on" variable here!
      background-color var(--color-primary-accent)
      color var(--color-white)
      display flex
      align-items center
      justify-content center
      font-weight 700
      border-radius 3rem
      font-size 1.5rem
      width 3rem
      height 3rem
      left -0.7rem
      top -0.7rem

      @media (max-width 900px)
        font-size 1.3rem
        width 2.7rem
        height 2.7rem
        left 0
        top 0

      @media (max-width 500px)
        font-size 1.3rem
        width 2.5rem
        height 2.5rem
        left -0.5rem
        top -0.5rem

  h1, h2, h3, p
    max-width 100%
