@import './css/vars.styl'

.single-post
  // Careful! This is probably some wordpress specific class
  &__wrapper
    general-container-outer()
    padding 8rem 0
    background-position top right
    background-repeat no-repeat
    background-size 50% auto

  &__inner
    general-container-inner()
    colored-links()

  &__content
    padding 4rem 0 0
    max-width 80ch

    img
      max-width 100%
      height auto
      margin 0 0 1.5rem