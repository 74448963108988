@import '../../css/vars.styl'

.component-free-text
  display flex
  justify-content var(--justify-content)

  colored-links()

  p, li
    p_max_width()

  &--ingress
    p, li
      font-size var(--font-size-h4)
      max-width 67ch

  [style*="text-align: center;"]
    margin-left auto
    margin-right auto