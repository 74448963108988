@import '../../css/vars.styl'

.component-columns
  general-container-outer()
  padding 3.5rem 0
  background-repeat no-repeat
  background-position right top
  background-size 50% auto

  &--colorize-inner
    background none !important

  &--colorize-inner &__column
    background var(--background-color)
    padding 2.2rem 2.2rem 1.8rem

  &--has-background-image
    padding 10rem 0 5rem

  &__inner
    general-container-inner()
    display flex
    gap 3rem
    flex-wrap wrap
    align-items var(--align-items)

  &__column
    flex 1

    &--half
      flex calc(1/2)

    &--one-third
      flex calc(1/3)

    &--two-thirds
      flex calc(2/3)

    @media (max-width 700px)
      min-width 100%

