@import '../../css/vars.styl'

padding-left()
  padding-left 1.5rem
  @media (max-width 1000px)
    padding-left 1.2rem
  @media (max-width 700px)
    padding-left 1rem

.component-faq-posts
  general-container-outer()
  padding 3.5rem 0

  &__inner
    general-container-inner()

  label
    cursor pointer
    position relative
    display flex
    min-height 60px
    align-items center
    padding 1.25rem
    transition background-color 0.05s ease
    padding-left()

    @media (max-width 1000px)
      height 55px
    @media (max-width 700px)
      height 50px

    &:hover
      background-color var(--color-button-background-primary)
      --color-text-headline var(--color-white)

      .component-faq-posts__arrow
        path
          fill white

    h2
      font-size var(--font-size-h4)
      padding 0
      max-width calc(100% - 3rem)
      font-weight 400
      line-height 1.4

  &__arrow
    transform rotate(0)
    position absolute
    right 1rem
    top 1.5rem
    transition transform 0.1s ease
    height 14px
    width auto

    @media (max-width 1000px)
      height 13px
    @media (max-width 700px)
      height 12px

  &__post
    border-bottom 1px solid var(--color-gray-600)
    colored-links()

    input[type="checkbox"]
      display none


  &__answer
    display none
    padding 0.5rem 1.5rem
    padding-left()

    p, h2, h3, h4, li
      max-width 85ch

  &__post input[type="checkbox"]:checked ~ &__answer
    display block

  &__post input[type="checkbox"]:checked ~ label &__arrow
    transform rotate(180deg)