@import '../../css/vars.styl'

.component-hero

  &__slides
    overflow hidden

  &__slide
    general-container-outer()
    min-height var(--dimensions-hero-height)
    background center no-repeat
    background-size cover
    position relative

    // This is for the blue thing going out all the way to the right...
    overflow hidden

    // This is for the blend overlay...
    &:after
      content " "
      position absolute
      height 100%
      width 100%
      left 0
      top 0
      background linear-gradient(var(--color-blue-700), #436772)
      mix-blend-mode hard-light
      opacity 0.5

  &__slide-inner
    general-container-inner()
    display flex
    align-items flex-start
    @media (max-width 700px)
      align-items center

  &__slide-content
    padding 3.125rem
    padding-left 0
    margin-top 7.5rem
    max-width 45%
    @media (max-width 1200px)
      margin-top 6rem
      padding 2.5rem
      padding-left 0
      max-width 50%
    @media (max-width 1000px)
      margin-top 4rem
      padding 2rem
      padding-left 0
      max-width 70%
    @media (max-width 700px)
      margin-top 0
      margin-bottom 2rem
      padding 1.5rem
      padding-left 0
      max-width 80%

    position relative
    border-top-right-radius var(--border-radius-xl)
    border-bottom-right-radius var(--border-radius-xl)
    z-index 1
    background var(--color-blue-700-transparent)

    p
      font-size var(--font-size-h3)
    
    // Colors
    --color-text-headline var(--color-white)
    --color-text var(--color-white)
    --color-button-background-primary var(--color-white)
    --color-button-background-primary-hover var(--color-blue-500)
    --color-button-text-primary var(--color-gray-100)
    --color-button-text-primary-hover var(--color-gray-100)

    // The blue thing going all the way out to the right
    &:after
      content " "
      position absolute
      height 100%
      width 100vw
      right 100%
      top 0
      background-color inherit

  &__slide-buttons
    padding 1rem 0 0

  &__dots
    position absolute
    z-index 1
    width 100%
    transform translateY(-100%)
    height 42px

    .glider-dot
      background none
      border 2px solid var(--color-white)

      &.active
        background var(--color-white)