@import '../../css/vars.styl'

.component-news-list
  general-container-outer()
  padding 3.5rem 0

  &__inner
    general-container-inner()

  &__post
    display flex
    flex-wrap wrap
    padding 0 0 4rem
    gap 2rem
    
    @media (max-width: 500px)
      gap 1rem

  &__content-above
    padding 0 0 0.5rem

  &__post-image,
  &__post-text
    flex 1

    @media (max-width: 500px)
      min-width 100%
    
  &__post-image img 
    max-width 100%
    height auto
    display block

  &__post-text
    padding 0.4rem 0
    @media (max-width: 830px)
      flex 1.5
 
  &__post-date
    color var(--color-primary-accent)

  &__read-more-link
    margin 2rem 0 0

// This is also userd in search.php!
.page-numbers
  font-weight 700
  padding 0 0.1rem

  &.current
    text-decoration underline
