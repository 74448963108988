@import './css/vars.styl'

.search
  &__wrapper
    general-container-outer()

  &__search-box
    general-container-inner()
    text-align center

    #searchform
      padding 5rem 0 3rem

  &__pagination
    general-container-inner()
    padding 0 0 5rem

  &__description
    text-align left
    padding 1rem 0 6rem