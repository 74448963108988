/* Load all dagny font files in this directory */
@font-face {
  font-family: "Dagny";
  src: url("FontFont - Dagny Offc Pro.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Dagny";
  src: url("FontFont - Dagny Offc Pro Italic.ttf") format("truetype");
  font-weight: normal;
  font-style: italic;
}
@font-face {
  font-family: "Dagny";
  /* src: url("FontFont - Dagny Offc Pro Black.ttf") format("truetype"); */
  src: url("FontFont - Dagny Offc Pro Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: "Dagny";
  /* src: url("FontFont - Dagny Offc Pro Black Italic.ttf") format("truetype"); */
  src: url("FontFont - Dagny Offc Pro Bold Italic.ttf") format("truetype");
  font-weight: bold;
  font-style: italic;
}
/* Semibold as well */
@font-face {
  font-family: "Dagny";
  src: url("FontFont - Dagny Offc Pro Bold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: "Dagny";
  src: url("FontFont - Dagny Offc Pro Bold Italic.ttf") format("truetype");
  font-weight: 600;
  font-style: italic;
}
