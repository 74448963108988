color-gray-900 = #F2F2F2
color-gray-800 = #EAE8E8
color-gray-700 = #bbb
color-gray-600 = #999999
color-gray-500 = #707070
color-gray-100 = #000

color-blue-700 = #85CEE4
color-blue-700-transparent = #85CEE4CC
color-blue-500 = #009baf
color-blue-300 = #048798
color-blue-200 = #007584

color-purple-700 = #E1C1DE
color-purple-500 = #8B5E8F

color-green-700 = #99C4C3
color-green-500 = #1DA195

color-pink-500 = #D67397
color-pink-300 = #A80053

color-beige-700 = #FDE1C6

color-white = #fff

button-height = 50px

mobile-breakpoint = 950px

:root
  --color-gray-900 color-gray-900
  --color-gray-800 color-gray-800
  --color-gray-700 color-gray-700
  --color-gray-600 color-gray-600
  --color-gray-500 color-gray-500
  --color-gray-100 color-gray-100

  --color-blue-700 color-blue-700
  --color-blue-700-transparent color-blue-700-transparent
  --color-blue-500 color-blue-500
  --color-blue-300 color-blue-300
  --color-blue-200 color-blue-200

  --color-purple-700 color-purple-700
  --color-purple-500 color-purple-500

  --color-green-700 color-green-700
  --color-green-500 color-green-500

  --color-pink-500 color-pink-500
  --color-pink-300 color-pink-300

  --color-beige-700 color-beige-700

  --color-white color-white


  // New SSF colors
  --ssf-primary #023953
  --ssf-secondary #e1197c
  --ssf-light-blue #dff2f7
  --ssf-blue-transparent #023953cc

  --color-link var(--color-blue-500)

  --color-footer-background var(--color-blue-500)
  --color-footer-text var(--color-white)

  --color-text var(--color-gray-100)
  --color-text-headline var(--color-gray-100)

  --color-primary-accent var(--color-blue-500)

  --color-button-background-primary var(--color-primary-accent)
  --color-button-background-primary-hover var(--color-blue-700)
  --color-button-text-primary var(--color-white)
  --color-button-text-primary-hover var(--color-white)
  --border-button-primary 1px solid transparent

  --color-button-background-secondary var(--color-purple-500)
  --color-button-background-secondary-hover var(--color-purple-700)
  --color-button-text-secondary var(--color-white)
  --color-button-text-secondary-hover var(--color-white)
  --border-button-secondary 1px solid transparent

  --color-button-background-tertiary transparent
  --color-button-background-tertiary-hover var(--color-white)
  --color-button-text-tertiary var(--color-gray-100)
  --color-button-text-tertiary-hover var(--color-gray-100)
  --border-button-tertiary 1px solid var(--color-gray-100)

  --color-dropdown-text var(--color-white)
  --color-dropdown-background var(--color-blue-300)
  --color-dropdown-background-hover var(--color-blue-200)

  // Dimensions stuff
  --button-height button-height
  @media (max-width 1200px)
    --button-height button-height - 4px
  @media (max-width 1000px)
    --button-height button-height - 10px
  @media (max-width 700px)
    --button-height button-height - 16px

  --dimensions-button-height var(--button-height)
  --dimensions-hero-height 645px
  @media (max-width 1200px)
    --dimensions-hero-height 600px
  @media (max-width 1000px)
    --dimensions-hero-height 450px
  @media (max-width 700px)
    --dimensions-hero-height 350px

  // Border radius stuff
  --border-radius-xl 75px
  --border-radius-s 14px
  --border-radius-xs 11px
  @media (max-width 1200px)
    --border-radius-xl 60px
  @media (max-width 1000px)
    --border-radius-xl 40px
    --border-radius-s 12px
    --border-radius-xs 8px
  @media (max-width 700px)
    --border-radius-xl 25px
    --border-radius-s 8px

  // Font sizes
  --font-size-h1 40px
  @media (max-width 1200px)
    --font-size-h1 35px
  @media (max-width 1000px)
    --font-size-h1 30px
  @media (max-width 700px)
    --font-size-h1 26px


  --font-size-h2 30px
  @media (max-width 1200px)
    --font-size-h2 27px
  @media (max-width 1000px)
    --font-size-h2 25px
  @media (max-width 700px)
    --font-size-h2 22px

  --font-size-h3 25px
  @media (max-width 1200px)
    --font-size-h3 22px
  @media (max-width 1000px)
    --font-size-h3 20px
  @media (max-width 700px)
    --font-size-h3 18px

  --font-size-h4 20px
  @media (max-width 1200px)
    --font-size-h4 18px
  @media (max-width 1000px)
    --font-size-h4 16px
  @media (max-width 700px)
    --font-size-h4 15px

.body--privat
  --color-primary-accent var(--ssf-primary)
  --color-button-background-primary var(--ssf-secondary)
  --color-dropdown-background var(--ssf-light-blue)
  --color-dropdown-background-hover var(--ssf-light-blue)
  --color-dropdown-text var(--ssf-primary)
  --color-link var(--ssf-secondary)
  --color-footer-background var(--ssf-primary)
  --color-blue-700-transparent var(--ssf-blue-transparent)
  --color-button-background-primary-hover var(--ssf-secondary)

// Mixins
general-container-outer()
  display flex
  justify-content center

general-container-inner()
  width 80%
  max-width 1200px

  @media (max-width 1200px)
    width 85%
  @media (max-width 1000px)
    width 90%
  @media (max-width 700px)
    width calc(100% - 3rem)

colored-links()
  & a
    color var(--color-link)

p_max_width()
  max-width 75ch
