@import '../../css/vars.styl'

.component-video
  general-container-outer()
  padding 3.5rem 0

  &__inner
    general-container-inner()

  &__video
    padding-top 56.25%
    position relative
    overflow hidden
    width 100%

    &--16_9
      padding-top 56.25%
    &--4_3
      padding-top 75%

    iframe
      position absolute
      top 0
      left 0
      bottom 0
      right 0
      width 100%
      height 100%
      background black

      // @media (max-width: 1200px)
      //   height 550px

      // @media (max-width: 1000px)
      //   height 500px

      // @media (max-width: 800px)
      //   height 400px

      // @media (max-width: 600px)
      //   height 300px

      // @media (max-width: 500px)
      //   height 270px

