@import '../../css/vars.styl'

.component-form
  general-container-outer()
  padding 3.5rem 0

  .component-free-text + &
    padding-top 1rem

  &__inner
    general-container-inner()

    .component-columns &
      width 100%

  // some resets...
  .nf-cell,
  .nf-multi-cell .nf-cell
    padding 0

  .nf-form-content 
    max-width 100%
    padding 0
    *
      font-size 1rem

  // labels styling
  .nf-field-label label
    font-weight 700
    text-transform uppercase

  // Input fields
  .nf-field-element
    input[type="text"],
    input[type="tel"],
    textarea,
    input[type="email"]
      background-color var(--color-white)
      border-radius var(--border-radius-xs)
      border-color var(--color-blue-700)
      font-family inherit
      font-size inherit
      
      &, &:focus
        color var(--color-text)        

  // Radio stuff
  .listradio-wrap .nf-field-element label
    &:after
      background var(--color-white)
      border 1px solid var(--color-gray-500)

  .listradio-wrap .nf-field-element label.nf-checked-label
    &:before
      background var(--color-primary-accent)
      top 5px

  nf-cells
    display flex
    gap 0 3rem
    flex-wrap wrap

    .nf-cell
      flex 1
      width auto
      
      @media (max-width: 600px)
        min-width 100%
        width 100%


// Make number fields look lite text fields...
input[type='number'] 
  -moz-appearance:textfield

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button 
  -webkit-appearance: none
