.component-pop-out-image
  display flex
  position absolute
  justify-content var(--justify-content)
  width 95%
  left 2.5%
  margin 0 auto
  pointer-events none

  @media (max-width: 700px)
    display none

  &__image
    transform translateY(-50%)

    &--half
      max-width calc(100% / 2)

    &--one-third
      max-width calc(100% / 3)

    &--two-thirds
      max-width calc(100% / 3)