@media (width <= 1200px) {
  :root {
    --button-height: 46px;
  }
}

@media (width <= 1000px) {
  :root {
    --button-height: 40px;
  }
}

@media (width <= 700px) {
  :root {
    --button-height: 34px;
  }
}

@media (width <= 1200px) {
  :root {
    --dimensions-hero-height: 600px;
  }
}

@media (width <= 1000px) {
  :root {
    --dimensions-hero-height: 450px;
  }
}

@media (width <= 700px) {
  :root {
    --dimensions-hero-height: 350px;
  }
}

@media (width <= 1200px) {
  :root {
    --border-radius-xl: 60px;
  }
}

@media (width <= 1000px) {
  :root {
    --border-radius-xl: 40px;
    --border-radius-s: 12px;
    --border-radius-xs: 8px;
  }
}

@media (width <= 700px) {
  :root {
    --border-radius-xl: 25px;
    --border-radius-s: 8px;
  }
}

@media (width <= 1200px) {
  :root {
    --font-size-h1: 35px;
  }
}

@media (width <= 1000px) {
  :root {
    --font-size-h1: 30px;
  }
}

@media (width <= 700px) {
  :root {
    --font-size-h1: 26px;
  }
}

@media (width <= 1200px) {
  :root {
    --font-size-h2: 27px;
  }
}

@media (width <= 1000px) {
  :root {
    --font-size-h2: 25px;
  }
}

@media (width <= 700px) {
  :root {
    --font-size-h2: 22px;
  }
}

@media (width <= 1200px) {
  :root {
    --font-size-h3: 22px;
  }
}

@media (width <= 1000px) {
  :root {
    --font-size-h3: 20px;
  }
}

@media (width <= 700px) {
  :root {
    --font-size-h3: 18px;
  }
}

@media (width <= 1200px) {
  :root {
    --font-size-h4: 18px;
  }
}

@media (width <= 1000px) {
  :root {
    --font-size-h4: 16px;
  }
}

@media (width <= 700px) {
  :root {
    --font-size-h4: 15px;
  }
}

html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  border: 0;
  margin: 0;
  padding: 0;
}

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after, q:before, q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

html {
  box-sizing: border-box;
}

*, :before, :after {
  box-sizing: inherit;
}

@font-face {
  font-family: Dagny;
  src: url("FontFont - Dagny Offc Pro.8654f4e8.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: Dagny;
  src: url("FontFont - Dagny Offc Pro Italic.2da1e9dd.ttf") format("truetype");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: Dagny;
  src: url("FontFont - Dagny Offc Pro Bold.988f5462.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: Dagny;
  src: url("FontFont - Dagny Offc Pro Bold Italic.0b0c5af4.ttf") format("truetype");
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: Dagny;
  src: url("FontFont - Dagny Offc Pro Bold.988f5462.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: Dagny;
  src: url("FontFont - Dagny Offc Pro Bold Italic.0b0c5af4.ttf") format("truetype");
  font-weight: 600;
  font-style: italic;
}

@media (width <= 1200px) {
  :root {
    --button-height: 46px;
  }
}

@media (width <= 1000px) {
  :root {
    --button-height: 40px;
  }
}

@media (width <= 700px) {
  :root {
    --button-height: 34px;
  }
}

@media (width <= 1200px) {
  :root {
    --dimensions-hero-height: 600px;
  }
}

@media (width <= 1000px) {
  :root {
    --dimensions-hero-height: 450px;
  }
}

@media (width <= 700px) {
  :root {
    --dimensions-hero-height: 350px;
  }
}

@media (width <= 1200px) {
  :root {
    --border-radius-xl: 60px;
  }
}

@media (width <= 1000px) {
  :root {
    --border-radius-xl: 40px;
    --border-radius-s: 12px;
    --border-radius-xs: 8px;
  }
}

@media (width <= 700px) {
  :root {
    --border-radius-xl: 25px;
    --border-radius-s: 8px;
  }
}

@media (width <= 1200px) {
  :root {
    --font-size-h1: 35px;
  }
}

@media (width <= 1000px) {
  :root {
    --font-size-h1: 30px;
  }
}

@media (width <= 700px) {
  :root {
    --font-size-h1: 26px;
  }
}

@media (width <= 1200px) {
  :root {
    --font-size-h2: 27px;
  }
}

@media (width <= 1000px) {
  :root {
    --font-size-h2: 25px;
  }
}

@media (width <= 700px) {
  :root {
    --font-size-h2: 22px;
  }
}

@media (width <= 1200px) {
  :root {
    --font-size-h3: 22px;
  }
}

@media (width <= 1000px) {
  :root {
    --font-size-h3: 20px;
  }
}

@media (width <= 700px) {
  :root {
    --font-size-h3: 18px;
  }
}

@media (width <= 1200px) {
  :root {
    --font-size-h4: 18px;
  }
}

@media (width <= 1000px) {
  :root {
    --font-size-h4: 16px;
  }
}

@media (width <= 700px) {
  :root {
    --font-size-h4: 15px;
  }
}

html {
  font-size: 16px;
}

@media (width <= 1200px) {
  html {
    font-size: 15px;
  }
}

@media (width <= 1000px) {
  html {
    font-size: 14px;
  }
}

@media (width <= 700px) {
  html {
    font-size: 13px;
  }
}

body {
  color: var(--color-gray-100);
  font-family: Dagny, sans-serif;
  line-height: 1.375;
}

h1, h2, h3 {
  color: var(--color-text-headline);
  font-weight: 700;
  line-height: 1.23;
}

h1 {
  font-size: var(--font-size-h1);
  max-width: 35ch;
  padding: 0 0 1.3rem;
}

h2 {
  font-size: var(--font-size-h2);
  max-width: 40ch;
  padding: 0 0 1rem;
}

h3 {
  font-size: var(--font-size-h3);
  padding: 0 0 .7rem;
}

h4 {
  font-size: var(--font-size-h4);
  padding: 0 0 .8rem;
}

p {
  color: var(--color-text);
  padding: 0 0 1rem;
  font-size: 1rem;
}

a {
  color: inherit;
  text-decoration: none;
}

a:hover {
  color: var(--color-text);
}

strong {
  font-weight: 600;
}

img.aligncenter {
  margin: 0 auto;
  display: block;
}

article ol, article ul {
  color: var(--color-text);
  padding: .5rem .5rem .5rem 2.5rem;
}

article ol li, article ul li {
  padding: 0 0 .5rem .5rem;
}

article ul li {
  list-style: disc;
}

article ol li {
  list-style: decimal;
}

#searchsubmit, #s {
  height: 32px;
  margin: 0;
  padding-top: 0;
  padding-bottom: 0;
  font-family: inherit;
}

#s {
  border: 1px solid var(--color-gray-600);
  border-radius: 2rem;
  min-width: 300px;
  margin-right: 1rem;
  padding-left: 1rem;
}

@media (width <= 950px) {
  #s {
    min-width: 0;
  }
}

.glider, .glider-contain {
  margin: 0 auto;
  position: relative;
}

.glider, .glider-track {
  transform: translateZ(0);
}

.glider-dot, .glider-next, .glider-prev {
  -webkit-user-select: none;
  user-select: none;
  border: 0;
  outline: 0;
  padding: 0;
}

.glider-contain {
  width: 100%;
}

.glider {
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: none;
  overflow-y: hidden;
}

.glider-track {
  z-index: 1;
  width: 100%;
  margin: 0;
  padding: 0;
  display: flex;
}

.glider.draggable {
  -webkit-user-select: none;
  user-select: none;
  cursor: -webkit-grab;
  cursor: grab;
}

.glider.draggable .glider-slide img {
  -webkit-user-select: none;
  user-select: none;
  pointer-events: none;
}

.glider.drag {
  cursor: -webkit-grabbing;
  cursor: grabbing;
}

.glider-slide {
  -webkit-user-select: none;
  user-select: none;
  place-content: center;
  width: 100%;
  min-width: 150px;
}

.glider-slide img {
  max-width: 100%;
}

.glider::-webkit-scrollbar {
  opacity: 0;
  height: 0;
}

.glider-next, .glider-prev {
  z-index: 2;
  cursor: pointer;
  color: #666;
  opacity: 1;
  background: none;
  font-size: 40px;
  line-height: 1;
  text-decoration: none;
  transition: opacity .5s cubic-bezier(.17, .67, .83, .67), color .5s cubic-bezier(.17, .67, .83, .67);
  position: absolute;
  top: 30%;
  left: -23px;
}

.glider-next:focus, .glider-next:hover, .glider-prev:focus, .glider-prev:hover {
  color: #ccc;
}

.glider-next {
  left: auto;
  right: -23px;
}

.glider-next.disabled, .glider-prev.disabled {
  opacity: .25;
  color: #666;
  cursor: default;
}

.glider-hide {
  opacity: 0;
}

.glider-dots {
  -webkit-user-select: none;
  user-select: none;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0 auto;
  padding: 0;
  display: flex;
}

.glider-dot {
  cursor: pointer;
  color: #ccc;
  background: #ccc;
  border-radius: 999px;
  width: 12px;
  height: 12px;
  margin: 7px;
  display: block;
}

.glider-dot:focus, .glider-dot:hover {
  background: #ddd;
}

.glider-dot.active {
  background: #a89cc8;
}

@media (width <= 36em) {
  .glider::-webkit-scrollbar {
    opacity: 1;
    -webkit-appearance: none;
    width: 7px;
    height: 3px;
  }

  .glider::-webkit-scrollbar-thumb {
    opacity: 1;
    background-color: #9c9c9c40;
    border-radius: 99px;
    box-shadow: 0 0 1px #ffffff40;
  }
}

@media (width <= 1200px) {
  :root {
    --button-height: 46px;
  }
}

@media (width <= 1000px) {
  :root {
    --button-height: 40px;
  }
}

@media (width <= 700px) {
  :root {
    --button-height: 34px;
  }
}

@media (width <= 1200px) {
  :root {
    --dimensions-hero-height: 600px;
  }
}

@media (width <= 1000px) {
  :root {
    --dimensions-hero-height: 450px;
  }
}

@media (width <= 700px) {
  :root {
    --dimensions-hero-height: 350px;
  }
}

@media (width <= 1200px) {
  :root {
    --border-radius-xl: 60px;
  }
}

@media (width <= 1000px) {
  :root {
    --border-radius-xl: 40px;
    --border-radius-s: 12px;
    --border-radius-xs: 8px;
  }
}

@media (width <= 700px) {
  :root {
    --border-radius-xl: 25px;
    --border-radius-s: 8px;
  }
}

@media (width <= 1200px) {
  :root {
    --font-size-h1: 35px;
  }
}

@media (width <= 1000px) {
  :root {
    --font-size-h1: 30px;
  }
}

@media (width <= 700px) {
  :root {
    --font-size-h1: 26px;
  }
}

@media (width <= 1200px) {
  :root {
    --font-size-h2: 27px;
  }
}

@media (width <= 1000px) {
  :root {
    --font-size-h2: 25px;
  }
}

@media (width <= 700px) {
  :root {
    --font-size-h2: 22px;
  }
}

@media (width <= 1200px) {
  :root {
    --font-size-h3: 22px;
  }
}

@media (width <= 1000px) {
  :root {
    --font-size-h3: 20px;
  }
}

@media (width <= 700px) {
  :root {
    --font-size-h3: 18px;
  }
}

@media (width <= 1200px) {
  :root {
    --font-size-h4: 18px;
  }
}

@media (width <= 1000px) {
  :root {
    --font-size-h4: 16px;
  }
}

@media (width <= 700px) {
  :root {
    --font-size-h4: 15px;
  }
}

.header {
  background: var(--color-white);
  z-index: 2;
  position: relative;
}

.header__top-menu {
  background: var(--color-primary-accent);
  color: var(--color-white);
  justify-content: center;
  font-weight: 600;
  display: flex;
}

.header__top-menu-inner {
  justify-content: space-between;
  align-items: center;
  width: 80%;
  max-width: 1200px;
  display: flex;
}

@media (width <= 1200px) {
  .header__top-menu-inner {
    width: 85%;
  }
}

@media (width <= 1000px) {
  .header__top-menu-inner {
    width: 90%;
  }
}

@media (width <= 700px) {
  .header__top-menu-inner {
    width: calc(100% - 3rem);
  }
}

@media (width <= 1000px) {
  .header__top-menu {
    font-size: 1rem;
  }
}

.header__top-menu > div {
  display: flex;
}

.header__top-menu .menu {
  height: 43px;
  display: flex;
  overflow: hidden;
}

@media (width <= 1000px) {
  .header__top-menu .menu {
    height: 36px;
  }
}

@media (width <= 600px) {
  .header__top-menu .menu {
    height: 32px;
  }
}

.header__top-menu .menu .menu-item {
  align-items: center;
  margin-right: 5rem;
  display: flex;
  position: relative;
}

@media (width <= 1000px) {
  .header__top-menu .menu .menu-item {
    margin-right: 3rem;
  }
}

@media (width <= 600px) {
  .header__top-menu .menu .menu-item {
    margin-right: 2rem;
  }
}

.header__top-menu .menu .menu-item.current-menu-item:after, .header__top-menu .menu .menu-item.current-page-ancestor:after {
  content: " ";
  background-color: var(--color-white);
  width: 30px;
  height: 30px;
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translate(-50%, -.1rem)rotate(45deg);
}

@media (width <= 1000px) {
  .header__top-menu .menu .menu-item.current-menu-item:after, .header__top-menu .menu .menu-item.current-page-ancestor:after {
    top: 105%;
  }
}

@media (width <= 600px) {
  .header__top-menu .menu .menu-item.current-menu-item:after, .header__top-menu .menu .menu-item.current-page-ancestor:after {
    top: 110%;
  }
}

.header__top-menu-image {
  width: auto;
  max-height: 43px;
  display: block;
  transform: translateX(4px);
}

@media (width <= 1000px) {
  .header__top-menu-image {
    max-height: 35px;
  }
}

@media (width <= 600px) {
  .header__top-menu-image {
    display: none;
  }
}

.header__logo {
  width: auto;
  height: 65px;
  display: block;
}

@media (width <= 1500px) {
  .header__logo {
    height: 60px;
  }
}

@media (width <= 1350px) {
  .header__logo {
    height: 58px;
  }
}

@media (width <= 1200px) {
  .header__logo {
    height: 53px;
  }
}

@media (width <= 1000px) {
  .header__logo {
    height: 50px !important;
  }
}

@media (width <= 950px) {
  .header__logo {
    height: 53px !important;
  }
}

@media (width <= 600px) {
  .header__logo {
    height: 40px !important;
  }
}

.header__second-row {
  justify-content: center;
  display: flex;
}

.header__second-row-inner {
  align-items: center;
  width: 80%;
  max-width: 1200px;
  height: 105px;
  display: flex;
}

@media (width <= 1200px) {
  .header__second-row-inner {
    width: 85%;
  }
}

@media (width <= 1000px) {
  .header__second-row-inner {
    width: 90%;
  }
}

@media (width <= 700px) {
  .header__second-row-inner {
    width: calc(100% - 3rem);
  }
}

@media (width <= 1000px) {
  .header__second-row-inner {
    height: 90px;
  }
}

@media (width <= 600px) {
  .header__second-row-inner {
    height: 70px;
  }
}

.header__navs {
  flex-wrap: wrap;
  flex: 1;
  justify-content: flex-end;
  display: flex;
}

.header__navs .current-menu-item > a, .header__navs .current_page_ancestor > a {
  text-decoration: underline;
  -webkit-text-decoration-color: var(--color-primary-accent);
  text-decoration-color: var(--color-primary-accent);
  text-underline-position: under;
}

.header__nav-menu-primary {
  padding: 0 0 .9rem;
  font-weight: 600;
}

@media (width >= 950px) {
  .header__nav-menu-primary .menu-item {
    padding-bottom: .5rem;
  }
}

.header__nav-menu-secondary {
  color: var(--color-primary-accent);
  justify-content: flex-end;
  align-items: center;
  gap: 1rem;
  padding: .5rem 0 .45rem;
  font-size: .9em;
  font-weight: 400;
  display: flex;
}

.header__nav-menu-secondary .current-menu-item {
  -webkit-text-decoration-color: var(--color-gray-100);
  text-decoration-color: var(--color-gray-100);
}

@media (width <= 950px) {
  .header__nav-menu-secondary {
    border-bottom: 1px solid var(--color-gray-800);
    flex-direction: column;
    align-items: flex-start;
  }

  .header__nav-menu-secondary > div {
    border-top: 1px solid;
    width: 100%;
  }
}

.header__nav-menu-secondary, .header__nav-menu-primary {
  width: 100%;
}

@media (width <= 950px) {
  .header__nav-menu-secondary, .header__nav-menu-primary {
    padding: 0;
  }
}

.header__nav-menu-secondary .menu, .header__nav-menu-primary .menu {
  justify-content: flex-end;
  display: flex;
  position: relative;
}

@media (width <= 950px) {
  .header__nav-menu-secondary .menu, .header__nav-menu-primary .menu {
    flex-direction: column;
  }
}

.header__nav-menu-secondary .menu .sub-menu, .header__nav-menu-primary .menu .sub-menu {
  font-weight: 400;
  display: none;
}

@media (width >= 950px) {
  .header__nav-menu-secondary .menu .sub-menu, .header__nav-menu-primary .menu .sub-menu {
    background: var(--color-white);
    flex-direction: column;
    padding: 1.3rem 1rem .8rem;
    position: absolute;
    top: 100%;
    right: 0;
    box-shadow: 0 3px 6px #00000026;
  }

  .header__nav-menu-secondary .menu .sub-menu .menu-item, .header__nav-menu-primary .menu .sub-menu .menu-item {
    white-space: nowrap;
    margin: 0;
    display: flex;
  }

  .header__nav-menu-secondary .menu .sub-menu .menu-item a, .header__nav-menu-primary .menu .sub-menu .menu-item a {
    padding: .2rem;
  }
}

@media (width <= 950px) {
  .header__nav-menu-secondary .menu .sub-menu, .header__nav-menu-primary .menu .sub-menu {
    border-top: 1px solid var(--color-gray-800);
    display: block;
  }

  .header__nav-menu-secondary .menu .sub-menu .menu-item a, .header__nav-menu-primary .menu .sub-menu .menu-item a {
    color: var(--color-gray-500);
    padding-left: 2rem;
  }
}

.header__nav-menu-secondary .menu .menu-item, .header__nav-menu-primary .menu .menu-item {
  margin-left: 3rem;
}

@media (width >= 950px) {
  .header__nav-menu-secondary .menu .menu-item:hover .sub-menu, .header__nav-menu-primary .menu .menu-item:hover .sub-menu {
    display: flex;
  }
}

@media (width <= 1500px) {
  .header__nav-menu-secondary .menu .menu-item, .header__nav-menu-primary .menu .menu-item {
    margin-left: 2.4rem;
  }
}

@media (width <= 1350px) {
  .header__nav-menu-secondary .menu .menu-item, .header__nav-menu-primary .menu .menu-item {
    margin-left: 1.3rem;
  }
}

@media (width <= 1000px) {
  .header__nav-menu-secondary .menu .menu-item, .header__nav-menu-primary .menu .menu-item {
    margin-left: 1.5rem;
  }
}

@media (width <= 950px) {
  .header__nav-menu-secondary .menu .menu-item, .header__nav-menu-primary .menu .menu-item {
    margin-left: 0;
  }

  .header__nav-menu-secondary .menu .menu-item:first-child a, .header__nav-menu-primary .menu .menu-item:first-child a {
    border-top: none;
  }

  .header__nav-menu-secondary .menu .menu-item a, .header__nav-menu-primary .menu .menu-item a {
    border-top: 1px solid var(--color-gray-800);
    min-width: 170px;
    padding: 1rem 3rem 1rem 1rem;
    display: block;
  }

  .header__nav-menu-secondary .menu .menu-item a:hover, .header__nav-menu-primary .menu .menu-item a:hover {
    background-color: var(--color-button-background-primary-hover);
    color: var(--color-white);
  }
}

.header__nav-menu-secondary .menu .menu-item-has-children, .header__nav-menu-primary .menu .menu-item-has-children {
  position: relative;
}

@media (width >= 950px) {
  .header__nav-menu-secondary .menu .menu-item-has-children, .header__nav-menu-primary .menu .menu-item-has-children {
    padding-right: 25px;
  }

  .header__nav-menu-secondary .menu .menu-item-has-children:after, .header__nav-menu-primary .menu .menu-item-has-children:after {
    content: " ";
    background: url("icon-sub-menu.a9366cc4.svg") 100% / cover no-repeat;
    width: 25px;
    height: 22px;
    transition: transform .1s;
    position: absolute;
    top: 0;
    right: 0;
  }

  .header__nav-menu-secondary .menu .menu-item-has-children:hover:after, .header__nav-menu-primary .menu .menu-item-has-children:hover:after {
    transform: rotate(180deg);
  }
}

.header__checkbox-mobile-menu, .header__checkbox-mobile-menu-label {
  display: none;
}

@media (width <= 950px) {
  .header__navs-wrapper {
    background: var(--color-white);
    border-radius: var(--border-radius-s);
    display: none;
    position: absolute;
    overflow: hidden;
    transform: translate(.6rem, -1rem);
    box-shadow: 0 2px 6px #0002;
  }

  .header__checkbox-mobile-menu-label {
    z-index: 1;
    display: block;
    position: relative;
  }

  .header__checkbox-mobile-menu-label .header__checkbox-mobile-menu-label--menu-visible {
    display: none;
  }

  .header__checkbox-mobile-menu-label img {
    cursor: pointer;
    width: auto;
    height: 32px;
    display: block;
  }

  .header__checkbox-mobile-menu:checked ~ .header__checkbox-mobile-menu-label .header__checkbox-mobile-menu-label--menu-visible {
    display: block;
  }

  .header__checkbox-mobile-menu:checked ~ .header__checkbox-mobile-menu-label .header__checkbox-mobile-menu-label--menu-hidden {
    display: none;
  }

  .header__checkbox-mobile-menu:checked ~ .header__navs-wrapper {
    display: block;
  }
}

.header__search, .header__search .searchform {
  align-items: center;
  gap: 1rem;
  display: flex;
}

.header__search {
  display: none;
}

.header__search-toggle {
  cursor: pointer;
  background: url("seach-icon.eac4a6c6.svg") 0 0 / cover no-repeat;
  width: 32px;
  height: 32px;
  display: block;
}

.body--privat .header__search-toggle {
  background: url("seach-icon--ssf.7e544c6a.svg") no-repeat;
}

@media (width <= 950px) {
  .header__search-toggle {
    order: -1;
    margin: 1rem 1rem 0;
  }
}

.header__search-toggle--close {
  background: url("close-seach-icon.a1e192e8.svg") no-repeat;
  order: 10;
}

.header__checkbox-search, .header__checkbox-search:checked ~ .header__search-toggle, .header__checkbox-search:checked + div {
  display: none;
}

.header__checkbox-search:checked ~ .header__search {
  display: flex;
}

@media (width <= 950px) and (width <= 700px) {
  .header img {
    height: 28px;
  }
}

@media (width <= 950px) and (width <= 500px) {
  .header img {
    height: 24px;
  }
}

.id-dropdown {
  background: var(--color-dropdown-background);
  color: var(--color-dropdown-text);
  min-width: 185px;
  padding: 0 .65rem;
  font-size: .8rem;
  font-weight: normal;
  transition: background .1s;
  position: relative;
}

.id-dropdown--open, .id-dropdown:hover {
  background: var(--color-dropdown-background-hover);
}

.id-dropdown__top-bar {
  cursor: pointer;
  white-space: nowrap;
  justify-content: center;
  align-items: center;
  gap: .8rem;
  display: flex;
}

.id-dropdown svg {
  width: 19px;
  height: auto;
  transform: translate(2px, -1px);
}

.id-dropdown__caret-icon-wrapper svg {
  color: var(--color-dropdown-text);
  width: 14px;
  margin-left: .1rem;
  margin-right: .4rem;
  transition: transform .1s;
  transform: rotate(0);
}

.id-dropdown--open .id-dropdown__caret-icon-wrapper svg {
  transform: rotate(180deg);
}

.id-dropdown__fold-out {
  z-index: 35;
  color: #000;
  background: #fff;
  flex-direction: column;
  width: 100%;
  padding: .9rem 1rem;
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  box-shadow: 0 1px 10px #0000001a;
}

.id-dropdown--open .id-dropdown__fold-out {
  display: flex;
}

.id-dropdown__hl {
  padding-bottom: .5rem;
  font-size: .85rem;
  font-weight: 600;
}

.id-dropdown .id-input {
  background: var(--color-gray-900);
  border: 1px solid var(--color-gray-700);
  text-align: center;
  border-radius: 3px;
  outline: none;
  width: 20px;
  height: 34px;
  padding: 0;
}

.id-dropdown .id-input:disabled {
  opacity: .5;
}

.id-dropdown__inputs {
  align-items: center;
  gap: .6rem;
  padding: 0 0 1.3rem;
  display: flex;
}

.id-dropdown__info {
  line-height: 1.5;
}

.id-dropdown__info svg {
  width: 18px;
  height: auto;
  margin-right: .3rem;
}

.id-dropdown__info svg path {
  fill: var(--color-primary-accent);
}

.id-dropdown__info_hl {
  align-items: center;
  padding: 0 0 .2rem;
  font-weight: 600;
  display: flex;
}

.lds-ellipsis {
  width: 80px;
  height: 27px;
  margin: 1px auto 0;
  display: none;
  position: relative;
}

.lds-ellipsis--active {
  display: block;
}

.lds-ellipsis div {
  background: var(--color-primary-accent);
  border-radius: 50%;
  width: 13px;
  height: 13px;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
  position: absolute;
  top: 0;
}

.lds-ellipsis div:first-child {
  animation: .6s infinite lds-ellipsis1;
  left: 8px;
}

.lds-ellipsis div:nth-child(2) {
  animation: .6s infinite lds-ellipsis2;
  left: 8px;
}

.lds-ellipsis div:nth-child(3) {
  animation: .6s infinite lds-ellipsis2;
  left: 32px;
}

.lds-ellipsis div:nth-child(4) {
  animation: .6s infinite lds-ellipsis3;
  left: 56px;
}

@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(0);
  }
}

@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0);
  }

  100% {
    transform: translate(24px);
  }
}

@media (width <= 1200px) {
  :root {
    --button-height: 46px;
  }
}

@media (width <= 1000px) {
  :root {
    --button-height: 40px;
  }
}

@media (width <= 700px) {
  :root {
    --button-height: 34px;
  }
}

@media (width <= 1200px) {
  :root {
    --dimensions-hero-height: 600px;
  }
}

@media (width <= 1000px) {
  :root {
    --dimensions-hero-height: 450px;
  }
}

@media (width <= 700px) {
  :root {
    --dimensions-hero-height: 350px;
  }
}

@media (width <= 1200px) {
  :root {
    --border-radius-xl: 60px;
  }
}

@media (width <= 1000px) {
  :root {
    --border-radius-xl: 40px;
    --border-radius-s: 12px;
    --border-radius-xs: 8px;
  }
}

@media (width <= 700px) {
  :root {
    --border-radius-xl: 25px;
    --border-radius-s: 8px;
  }
}

@media (width <= 1200px) {
  :root {
    --font-size-h1: 35px;
  }
}

@media (width <= 1000px) {
  :root {
    --font-size-h1: 30px;
  }
}

@media (width <= 700px) {
  :root {
    --font-size-h1: 26px;
  }
}

@media (width <= 1200px) {
  :root {
    --font-size-h2: 27px;
  }
}

@media (width <= 1000px) {
  :root {
    --font-size-h2: 25px;
  }
}

@media (width <= 700px) {
  :root {
    --font-size-h2: 22px;
  }
}

@media (width <= 1200px) {
  :root {
    --font-size-h3: 22px;
  }
}

@media (width <= 1000px) {
  :root {
    --font-size-h3: 20px;
  }
}

@media (width <= 700px) {
  :root {
    --font-size-h3: 18px;
  }
}

@media (width <= 1200px) {
  :root {
    --font-size-h4: 18px;
  }
}

@media (width <= 1000px) {
  :root {
    --font-size-h4: 16px;
  }
}

@media (width <= 700px) {
  :root {
    --font-size-h4: 15px;
  }
}

.footer {
  background: var(--color-footer-background);
  --color-text: var(--color-footer-text);
  justify-content: center;
  padding: 3.5rem 0;
  display: flex;
}

.footer__inner {
  flex-direction: column;
  align-items: center;
  gap: 1.5rem;
  width: 80%;
  max-width: 1200px;
  display: flex;
}

@media (width <= 1200px) {
  .footer__inner {
    width: 85%;
  }
}

@media (width <= 1000px) {
  .footer__inner {
    width: 90%;
  }
}

@media (width <= 700px) {
  .footer__inner {
    width: calc(100% - 3rem);
  }
}

.footer__icons p {
  justify-content: center;
  gap: 1rem;
  display: flex;
}

.sticky-link {
  z-index: 2;
  background: #fff0;
  border-top-left-radius: 10rem;
  border-bottom-left-radius: 10rem;
  align-items: center;
  display: flex;
  position: fixed;
  top: 50%;
  right: 0;
  overflow: hidden;
  transform: translate(calc(100% - 65px), -50%);
}

@media (width <= 600px) {
  .sticky-link {
    top: auto;
    bottom: 10%;
    transform: translate(calc(100% - 55px), -50%);
  }
}

.sticky-link--inited {
  transition: transform .25s, background .15s;
}

.sticky-link:hover, .sticky-link:focus {
  transform: translate(0, -50%);
}

.sticky-link img {
  border-radius: 10rem;
  width: 65px;
  height: 65px;
  overflow: hidden;
}

@media (width <= 600px) {
  .sticky-link img {
    width: 55px;
    height: 55px;
  }
}

.sticky-link__text {
  padding: 0 1.6rem 0 1.3rem;
  font-weight: 700;
}

.sticky-link--privat:hover {
  background: var(--color-dropdown-background);
}

.sticky-link--privat img {
  padding: .2rem;
}

.sticky-link--business:hover {
  background: var(--color-purple-700);
}

.sticky-link--business img {
  background: var(--color-purple-500);
}

@media (width <= 1200px) {
  :root {
    --button-height: 46px;
  }
}

@media (width <= 1000px) {
  :root {
    --button-height: 40px;
  }
}

@media (width <= 700px) {
  :root {
    --button-height: 34px;
  }
}

@media (width <= 1200px) {
  :root {
    --dimensions-hero-height: 600px;
  }
}

@media (width <= 1000px) {
  :root {
    --dimensions-hero-height: 450px;
  }
}

@media (width <= 700px) {
  :root {
    --dimensions-hero-height: 350px;
  }
}

@media (width <= 1200px) {
  :root {
    --border-radius-xl: 60px;
  }
}

@media (width <= 1000px) {
  :root {
    --border-radius-xl: 40px;
    --border-radius-s: 12px;
    --border-radius-xs: 8px;
  }
}

@media (width <= 700px) {
  :root {
    --border-radius-xl: 25px;
    --border-radius-s: 8px;
  }
}

@media (width <= 1200px) {
  :root {
    --font-size-h1: 35px;
  }
}

@media (width <= 1000px) {
  :root {
    --font-size-h1: 30px;
  }
}

@media (width <= 700px) {
  :root {
    --font-size-h1: 26px;
  }
}

@media (width <= 1200px) {
  :root {
    --font-size-h2: 27px;
  }
}

@media (width <= 1000px) {
  :root {
    --font-size-h2: 25px;
  }
}

@media (width <= 700px) {
  :root {
    --font-size-h2: 22px;
  }
}

@media (width <= 1200px) {
  :root {
    --font-size-h3: 22px;
  }
}

@media (width <= 1000px) {
  :root {
    --font-size-h3: 20px;
  }
}

@media (width <= 700px) {
  :root {
    --font-size-h3: 18px;
  }
}

@media (width <= 1200px) {
  :root {
    --font-size-h4: 18px;
  }
}

@media (width <= 1000px) {
  :root {
    --font-size-h4: 16px;
  }
}

@media (width <= 700px) {
  :root {
    --font-size-h4: 15px;
  }
}

.single-post__wrapper {
  background-position: 100% 0;
  background-repeat: no-repeat;
  background-size: 50%;
  justify-content: center;
  padding: 8rem 0;
  display: flex;
}

.single-post__inner {
  width: 80%;
  max-width: 1200px;
}

@media (width <= 1200px) {
  .single-post__inner {
    width: 85%;
  }
}

@media (width <= 1000px) {
  .single-post__inner {
    width: 90%;
  }
}

@media (width <= 700px) {
  .single-post__inner {
    width: calc(100% - 3rem);
  }
}

.single-post__inner a {
  color: var(--color-link);
}

.single-post__content {
  max-width: 80ch;
  padding: 4rem 0 0;
}

.single-post__content img {
  max-width: 100%;
  height: auto;
  margin: 0 0 1.5rem;
}

@media (width <= 1200px) {
  :root {
    --button-height: 46px;
  }
}

@media (width <= 1000px) {
  :root {
    --button-height: 40px;
  }
}

@media (width <= 700px) {
  :root {
    --button-height: 34px;
  }
}

@media (width <= 1200px) {
  :root {
    --dimensions-hero-height: 600px;
  }
}

@media (width <= 1000px) {
  :root {
    --dimensions-hero-height: 450px;
  }
}

@media (width <= 700px) {
  :root {
    --dimensions-hero-height: 350px;
  }
}

@media (width <= 1200px) {
  :root {
    --border-radius-xl: 60px;
  }
}

@media (width <= 1000px) {
  :root {
    --border-radius-xl: 40px;
    --border-radius-s: 12px;
    --border-radius-xs: 8px;
  }
}

@media (width <= 700px) {
  :root {
    --border-radius-xl: 25px;
    --border-radius-s: 8px;
  }
}

@media (width <= 1200px) {
  :root {
    --font-size-h1: 35px;
  }
}

@media (width <= 1000px) {
  :root {
    --font-size-h1: 30px;
  }
}

@media (width <= 700px) {
  :root {
    --font-size-h1: 26px;
  }
}

@media (width <= 1200px) {
  :root {
    --font-size-h2: 27px;
  }
}

@media (width <= 1000px) {
  :root {
    --font-size-h2: 25px;
  }
}

@media (width <= 700px) {
  :root {
    --font-size-h2: 22px;
  }
}

@media (width <= 1200px) {
  :root {
    --font-size-h3: 22px;
  }
}

@media (width <= 1000px) {
  :root {
    --font-size-h3: 20px;
  }
}

@media (width <= 700px) {
  :root {
    --font-size-h3: 18px;
  }
}

@media (width <= 1200px) {
  :root {
    --font-size-h4: 18px;
  }
}

@media (width <= 1000px) {
  :root {
    --font-size-h4: 16px;
  }
}

@media (width <= 700px) {
  :root {
    --font-size-h4: 15px;
  }
}

.search__wrapper {
  justify-content: center;
  display: flex;
}

.search__search-box {
  text-align: center;
  width: 80%;
  max-width: 1200px;
}

@media (width <= 1200px) {
  .search__search-box {
    width: 85%;
  }
}

@media (width <= 1000px) {
  .search__search-box {
    width: 90%;
  }
}

@media (width <= 700px) {
  .search__search-box {
    width: calc(100% - 3rem);
  }
}

.search__search-box #searchform {
  padding: 5rem 0 3rem;
}

.search__pagination {
  width: 80%;
  max-width: 1200px;
  padding: 0 0 5rem;
}

@media (width <= 1200px) {
  .search__pagination {
    width: 85%;
  }
}

@media (width <= 1000px) {
  .search__pagination {
    width: 90%;
  }
}

@media (width <= 700px) {
  .search__pagination {
    width: calc(100% - 3rem);
  }
}

.search__description {
  text-align: left;
  padding: 1rem 0 6rem;
}

@media (width <= 1200px) {
  :root {
    --button-height: 46px;
  }
}

@media (width <= 1000px) {
  :root {
    --button-height: 40px;
  }
}

@media (width <= 700px) {
  :root {
    --button-height: 34px;
  }
}

@media (width <= 1200px) {
  :root {
    --dimensions-hero-height: 600px;
  }
}

@media (width <= 1000px) {
  :root {
    --dimensions-hero-height: 450px;
  }
}

@media (width <= 700px) {
  :root {
    --dimensions-hero-height: 350px;
  }
}

@media (width <= 1200px) {
  :root {
    --border-radius-xl: 60px;
  }
}

@media (width <= 1000px) {
  :root {
    --border-radius-xl: 40px;
    --border-radius-s: 12px;
    --border-radius-xs: 8px;
  }
}

@media (width <= 700px) {
  :root {
    --border-radius-xl: 25px;
    --border-radius-s: 8px;
  }
}

@media (width <= 1200px) {
  :root {
    --font-size-h1: 35px;
  }
}

@media (width <= 1000px) {
  :root {
    --font-size-h1: 30px;
  }
}

@media (width <= 700px) {
  :root {
    --font-size-h1: 26px;
  }
}

@media (width <= 1200px) {
  :root {
    --font-size-h2: 27px;
  }
}

@media (width <= 1000px) {
  :root {
    --font-size-h2: 25px;
  }
}

@media (width <= 700px) {
  :root {
    --font-size-h2: 22px;
  }
}

@media (width <= 1200px) {
  :root {
    --font-size-h3: 22px;
  }
}

@media (width <= 1000px) {
  :root {
    --font-size-h3: 20px;
  }
}

@media (width <= 700px) {
  :root {
    --font-size-h3: 18px;
  }
}

@media (width <= 1200px) {
  :root {
    --font-size-h4: 18px;
  }
}

@media (width <= 1000px) {
  :root {
    --font-size-h4: 16px;
  }
}

@media (width <= 700px) {
  :root {
    --font-size-h4: 15px;
  }
}

.component-brand-assets {
  justify-content: center;
  padding: 3.5rem 0;
  display: flex;
}

.component-brand-assets__inner {
  width: 80%;
  max-width: 1200px;
}

@media (width <= 1200px) {
  .component-brand-assets__inner {
    width: 85%;
  }
}

@media (width <= 1000px) {
  .component-brand-assets__inner {
    width: 90%;
  }
}

@media (width <= 700px) {
  .component-brand-assets__inner {
    width: calc(100% - 3rem);
  }
}

.component-brand-assets__inner a {
  color: var(--color-link);
}

.component-brand-assets__content-above {
  padding: 0 0 1.5rem;
}

.component-brand-assets__posts {
  flex-wrap: wrap;
  gap: 2rem;
  padding: 0 0 2rem;
  display: flex;
}

.component-brand-assets__post {
  flex: 1;
  min-width: 26%;
  max-width: 33%;
  padding: 0 0 1rem;
}

@media (width <= 800px) {
  .component-brand-assets__post {
    min-width: 35%;
    max-width: 50%;
  }
}

.component-brand-assets__post h2 a {
  color: var(--color-text);
}

.component-brand-assets__post h2 a:hover {
  color: var(--color-link);
}

.component-brand-assets__post img {
  object-fit: scale-down;
  background-color: var(--color-gray-900);
  width: 100%;
  height: 200px;
  margin: 0 0 1.3rem;
}

@media (width <= 1200px) {
  .component-brand-assets__post img {
    height: 180px;
  }
}

@media (width <= 1000px) {
  .component-brand-assets__post img {
    height: 170px;
  }
}

@media (width <= 800px) {
  .component-brand-assets__post img {
    height: 160px;
  }
}

@media (width <= 600px) {
  .component-brand-assets__post img {
    height: 140px;
  }
}

.component-image-list + .component-buttons, .component-free-text + .component-buttons {
  padding-top: 1rem;
}

.component-buttons__inner {
  justify-content: var(--justify-content);
  flex-wrap: wrap;
  display: flex;
}

@media (width <= 700px) {
  .component-buttons__inner {
    justify-content: center;
  }
}

.component-button {
  cursor: pointer;
  min-height: calc(var(--dimensions-button-height)  - 20px);
  justify-content: center;
  align-items: center;
  margin: 0 1rem 1rem 0;
  font-family: inherit;
  font-weight: 700;
  text-decoration: none;
  display: inline-flex;
}

.component-button--button {
  border-radius: 5px;
  padding: 10px 2rem;
}

@media (width <= 1200px) {
  .component-button--button {
    padding-left: 1.6rem;
    padding-right: 1.6rem;
  }
}

@media (width <= 1000px) {
  .component-button--button {
    padding-left: 1.4rem;
    padding-right: 1.4rem;
  }
}

@media (width <= 700px) {
  .component-button--button {
    padding-left: 1.2rem;
    padding-right: 1.2rem;
  }
}

.component-button--button.component-button--primary {
  background: var(--color-button-background-primary);
  color: var(--color-button-text-primary);
  border: var(--border-button-primary);
}

.component-button--button.component-button--primary:hover {
  background: var(--color-button-background-primary-hover);
  color: var(--color-button-text-primary-hover);
}

.component-button--button.component-button--secondary {
  background: var(--color-button-background-secondary);
  color: var(--color-button-text-secondary);
  border: var(--border-button-secondary);
}

.component-button--button.component-button--secondary:hover {
  background: var(--color-button-background-secondary-hover);
  color: var(--color-button-text-secondary-hover);
}

.component-button--button.component-button--tertiary {
  background: var(--color-button-background-tertiary);
  color: var(--color-button-text-tertiary);
  border: var(--border-button-tertiary);
}

.component-button--button.component-button--tertiary:hover {
  background: var(--color-button-background-tertiary-hover);
  color: var(--color-button-text-tertiary-hover);
}

.component-button--link {
  color: var(--color-text);
  white-space: nowrap;
}

.component-button--link:after {
  content: " ";
  border-right: 2px solid var(--color-text);
  border-top: 2px solid var(--color-text);
  width: 10px;
  height: 10px;
  margin-left: 1rem;
  transform: rotate(45deg);
}

.component-button--link.component-button--primary:hover {
  color: var(--color-button-background-primary);
}

.component-button--link.component-button--secondary:hover {
  color: var(--color-button-background-secondary);
}

nf-cells nf-field input[type="button"].ninja-forms-field.nf-element {
  cursor: pointer;
  min-height: calc(var(--dimensions-button-height)  - 20px);
  background: var(--color-button-background-secondary);
  color: var(--color-button-text-secondary);
  border: var(--border-button-secondary);
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  margin: 0 1rem 1rem 0;
  padding: 10px 2rem;
  font-family: inherit;
  font-weight: 700;
  text-decoration: none;
  display: inline-flex;
}

@media (width <= 1200px) {
  nf-cells nf-field input[type="button"].ninja-forms-field.nf-element {
    padding-left: 1.6rem;
    padding-right: 1.6rem;
  }
}

@media (width <= 1000px) {
  nf-cells nf-field input[type="button"].ninja-forms-field.nf-element {
    padding-left: 1.4rem;
    padding-right: 1.4rem;
  }
}

@media (width <= 700px) {
  nf-cells nf-field input[type="button"].ninja-forms-field.nf-element {
    padding-left: 1.2rem;
    padding-right: 1.2rem;
  }
}

nf-cells nf-field input[type="button"].ninja-forms-field.nf-element:hover {
  background: var(--color-button-background-secondary-hover);
  color: var(--color-button-text-secondary-hover);
}

.privat-foretag-choice__button {
  cursor: pointer;
  min-height: calc(var(--dimensions-button-height)  - 20px);
  background: var(--color-button-background-primary);
  color: var(--color-button-text-primary);
  border: var(--border-button-primary);
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  margin: 1rem 0;
  padding: 10px 2rem;
  font-family: inherit;
  font-weight: 700;
  text-decoration: none;
  display: inline-flex;
}

@media (width <= 1200px) {
  .privat-foretag-choice__button {
    padding-left: 1.6rem;
    padding-right: 1.6rem;
  }
}

@media (width <= 1000px) {
  .privat-foretag-choice__button {
    padding-left: 1.4rem;
    padding-right: 1.4rem;
  }
}

@media (width <= 700px) {
  .privat-foretag-choice__button {
    padding-left: 1.2rem;
    padding-right: 1.2rem;
  }
}

.privat-foretag-choice__button:hover {
  background: var(--color-button-background-primary-hover);
  color: var(--color-button-text-primary-hover);
}

@media (width <= 1200px) {
  :root {
    --button-height: 46px;
  }
}

@media (width <= 1000px) {
  :root {
    --button-height: 40px;
  }
}

@media (width <= 700px) {
  :root {
    --button-height: 34px;
  }
}

@media (width <= 1200px) {
  :root {
    --dimensions-hero-height: 600px;
  }
}

@media (width <= 1000px) {
  :root {
    --dimensions-hero-height: 450px;
  }
}

@media (width <= 700px) {
  :root {
    --dimensions-hero-height: 350px;
  }
}

@media (width <= 1200px) {
  :root {
    --border-radius-xl: 60px;
  }
}

@media (width <= 1000px) {
  :root {
    --border-radius-xl: 40px;
    --border-radius-s: 12px;
    --border-radius-xs: 8px;
  }
}

@media (width <= 700px) {
  :root {
    --border-radius-xl: 25px;
    --border-radius-s: 8px;
  }
}

@media (width <= 1200px) {
  :root {
    --font-size-h1: 35px;
  }
}

@media (width <= 1000px) {
  :root {
    --font-size-h1: 30px;
  }
}

@media (width <= 700px) {
  :root {
    --font-size-h1: 26px;
  }
}

@media (width <= 1200px) {
  :root {
    --font-size-h2: 27px;
  }
}

@media (width <= 1000px) {
  :root {
    --font-size-h2: 25px;
  }
}

@media (width <= 700px) {
  :root {
    --font-size-h2: 22px;
  }
}

@media (width <= 1200px) {
  :root {
    --font-size-h3: 22px;
  }
}

@media (width <= 1000px) {
  :root {
    --font-size-h3: 20px;
  }
}

@media (width <= 700px) {
  :root {
    --font-size-h3: 18px;
  }
}

@media (width <= 1200px) {
  :root {
    --font-size-h4: 18px;
  }
}

@media (width <= 1000px) {
  :root {
    --font-size-h4: 16px;
  }
}

@media (width <= 700px) {
  :root {
    --font-size-h4: 15px;
  }
}

.component-columns {
  background-position: 100% 0;
  background-repeat: no-repeat;
  background-size: 50%;
  justify-content: center;
  padding: 3.5rem 0;
  display: flex;
}

.component-columns--colorize-inner {
  background: none !important;
}

.component-columns--colorize-inner .component-columns__column {
  background: var(--background-color);
  padding: 2.2rem 2.2rem 1.8rem;
}

.component-columns--has-background-image {
  padding: 10rem 0 5rem;
}

.component-columns__inner {
  gap: 3rem;
  align-items: var(--align-items);
  flex-wrap: wrap;
  width: 80%;
  max-width: 1200px;
  display: flex;
}

@media (width <= 1200px) {
  .component-columns__inner {
    width: 85%;
  }
}

@media (width <= 1000px) {
  .component-columns__inner {
    width: 90%;
  }
}

@media (width <= 700px) {
  .component-columns__inner {
    width: calc(100% - 3rem);
  }
}

.component-columns__column {
  flex: 1;
}

.component-columns__column--half {
  flex: .5;
}

.component-columns__column--one-third {
  flex: .333333;
}

.component-columns__column--two-thirds {
  flex: .666667;
}

@media (width <= 700px) {
  .component-columns__column {
    min-width: 100%;
  }
}

@media (width <= 1200px) {
  :root {
    --button-height: 46px;
  }
}

@media (width <= 1000px) {
  :root {
    --button-height: 40px;
  }
}

@media (width <= 700px) {
  :root {
    --button-height: 34px;
  }
}

@media (width <= 1200px) {
  :root {
    --dimensions-hero-height: 600px;
  }
}

@media (width <= 1000px) {
  :root {
    --dimensions-hero-height: 450px;
  }
}

@media (width <= 700px) {
  :root {
    --dimensions-hero-height: 350px;
  }
}

@media (width <= 1200px) {
  :root {
    --border-radius-xl: 60px;
  }
}

@media (width <= 1000px) {
  :root {
    --border-radius-xl: 40px;
    --border-radius-s: 12px;
    --border-radius-xs: 8px;
  }
}

@media (width <= 700px) {
  :root {
    --border-radius-xl: 25px;
    --border-radius-s: 8px;
  }
}

@media (width <= 1200px) {
  :root {
    --font-size-h1: 35px;
  }
}

@media (width <= 1000px) {
  :root {
    --font-size-h1: 30px;
  }
}

@media (width <= 700px) {
  :root {
    --font-size-h1: 26px;
  }
}

@media (width <= 1200px) {
  :root {
    --font-size-h2: 27px;
  }
}

@media (width <= 1000px) {
  :root {
    --font-size-h2: 25px;
  }
}

@media (width <= 700px) {
  :root {
    --font-size-h2: 22px;
  }
}

@media (width <= 1200px) {
  :root {
    --font-size-h3: 22px;
  }
}

@media (width <= 1000px) {
  :root {
    --font-size-h3: 20px;
  }
}

@media (width <= 700px) {
  :root {
    --font-size-h3: 18px;
  }
}

@media (width <= 1200px) {
  :root {
    --font-size-h4: 18px;
  }
}

@media (width <= 1000px) {
  :root {
    --font-size-h4: 16px;
  }
}

@media (width <= 700px) {
  :root {
    --font-size-h4: 15px;
  }
}

.component-faq-posts {
  justify-content: center;
  padding: 3.5rem 0;
  display: flex;
}

.component-faq-posts__inner {
  width: 80%;
  max-width: 1200px;
}

@media (width <= 1200px) {
  .component-faq-posts__inner {
    width: 85%;
  }
}

@media (width <= 1000px) {
  .component-faq-posts__inner {
    width: 90%;
  }
}

@media (width <= 700px) {
  .component-faq-posts__inner {
    width: calc(100% - 3rem);
  }
}

.component-faq-posts label {
  cursor: pointer;
  align-items: center;
  min-height: 60px;
  padding: 1.25rem 1.25rem 1.25rem 1.5rem;
  transition: background-color 50ms;
  display: flex;
  position: relative;
}

@media (width <= 1000px) {
  .component-faq-posts label {
    padding-left: 1.2rem;
  }
}

@media (width <= 700px) {
  .component-faq-posts label {
    padding-left: 1rem;
  }
}

@media (width <= 1000px) {
  .component-faq-posts label {
    height: 55px;
  }
}

@media (width <= 700px) {
  .component-faq-posts label {
    height: 50px;
  }
}

.component-faq-posts label:hover {
  background-color: var(--color-button-background-primary);
  --color-text-headline: var(--color-white);
}

.component-faq-posts label:hover .component-faq-posts__arrow path {
  fill: #fff;
}

.component-faq-posts label h2 {
  font-size: var(--font-size-h4);
  max-width: calc(100% - 3rem);
  padding: 0;
  font-weight: 400;
  line-height: 1.4;
}

.component-faq-posts__arrow {
  width: auto;
  height: 14px;
  transition: transform .1s;
  position: absolute;
  top: 1.5rem;
  right: 1rem;
  transform: rotate(0);
}

@media (width <= 1000px) {
  .component-faq-posts__arrow {
    height: 13px;
  }
}

@media (width <= 700px) {
  .component-faq-posts__arrow {
    height: 12px;
  }
}

.component-faq-posts__post {
  border-bottom: 1px solid var(--color-gray-600);
}

.component-faq-posts__post a {
  color: var(--color-link);
}

.component-faq-posts__post input[type="checkbox"] {
  display: none;
}

.component-faq-posts__answer {
  padding: .5rem 1.5rem;
  display: none;
}

@media (width <= 1000px) {
  .component-faq-posts__answer {
    padding-left: 1.2rem;
  }
}

@media (width <= 700px) {
  .component-faq-posts__answer {
    padding-left: 1rem;
  }
}

.component-faq-posts__answer p, .component-faq-posts__answer h2, .component-faq-posts__answer h3, .component-faq-posts__answer h4, .component-faq-posts__answer li {
  max-width: 85ch;
}

.component-faq-posts__post input[type="checkbox"]:checked ~ .component-faq-posts__answer {
  display: block;
}

.component-faq-posts__post input[type="checkbox"]:checked ~ label .component-faq-posts__arrow {
  transform: rotate(180deg);
}

@media (width <= 1200px) {
  :root {
    --button-height: 46px;
  }
}

@media (width <= 1000px) {
  :root {
    --button-height: 40px;
  }
}

@media (width <= 700px) {
  :root {
    --button-height: 34px;
  }
}

@media (width <= 1200px) {
  :root {
    --dimensions-hero-height: 600px;
  }
}

@media (width <= 1000px) {
  :root {
    --dimensions-hero-height: 450px;
  }
}

@media (width <= 700px) {
  :root {
    --dimensions-hero-height: 350px;
  }
}

@media (width <= 1200px) {
  :root {
    --border-radius-xl: 60px;
  }
}

@media (width <= 1000px) {
  :root {
    --border-radius-xl: 40px;
    --border-radius-s: 12px;
    --border-radius-xs: 8px;
  }
}

@media (width <= 700px) {
  :root {
    --border-radius-xl: 25px;
    --border-radius-s: 8px;
  }
}

@media (width <= 1200px) {
  :root {
    --font-size-h1: 35px;
  }
}

@media (width <= 1000px) {
  :root {
    --font-size-h1: 30px;
  }
}

@media (width <= 700px) {
  :root {
    --font-size-h1: 26px;
  }
}

@media (width <= 1200px) {
  :root {
    --font-size-h2: 27px;
  }
}

@media (width <= 1000px) {
  :root {
    --font-size-h2: 25px;
  }
}

@media (width <= 700px) {
  :root {
    --font-size-h2: 22px;
  }
}

@media (width <= 1200px) {
  :root {
    --font-size-h3: 22px;
  }
}

@media (width <= 1000px) {
  :root {
    --font-size-h3: 20px;
  }
}

@media (width <= 700px) {
  :root {
    --font-size-h3: 18px;
  }
}

@media (width <= 1200px) {
  :root {
    --font-size-h4: 18px;
  }
}

@media (width <= 1000px) {
  :root {
    --font-size-h4: 16px;
  }
}

@media (width <= 700px) {
  :root {
    --font-size-h4: 15px;
  }
}

.component-free-text {
  justify-content: var(--justify-content);
  display: flex;
}

.component-free-text a {
  color: var(--color-link);
}

.component-free-text p, .component-free-text li {
  max-width: 75ch;
}

.component-free-text--ingress p, .component-free-text--ingress li {
  font-size: var(--font-size-h4);
  max-width: 67ch;
}

.component-free-text [style*="text-align: center;"] {
  margin-left: auto;
  margin-right: auto;
}

@media (width <= 1200px) {
  :root {
    --button-height: 46px;
  }
}

@media (width <= 1000px) {
  :root {
    --button-height: 40px;
  }
}

@media (width <= 700px) {
  :root {
    --button-height: 34px;
  }
}

@media (width <= 1200px) {
  :root {
    --dimensions-hero-height: 600px;
  }
}

@media (width <= 1000px) {
  :root {
    --dimensions-hero-height: 450px;
  }
}

@media (width <= 700px) {
  :root {
    --dimensions-hero-height: 350px;
  }
}

@media (width <= 1200px) {
  :root {
    --border-radius-xl: 60px;
  }
}

@media (width <= 1000px) {
  :root {
    --border-radius-xl: 40px;
    --border-radius-s: 12px;
    --border-radius-xs: 8px;
  }
}

@media (width <= 700px) {
  :root {
    --border-radius-xl: 25px;
    --border-radius-s: 8px;
  }
}

@media (width <= 1200px) {
  :root {
    --font-size-h1: 35px;
  }
}

@media (width <= 1000px) {
  :root {
    --font-size-h1: 30px;
  }
}

@media (width <= 700px) {
  :root {
    --font-size-h1: 26px;
  }
}

@media (width <= 1200px) {
  :root {
    --font-size-h2: 27px;
  }
}

@media (width <= 1000px) {
  :root {
    --font-size-h2: 25px;
  }
}

@media (width <= 700px) {
  :root {
    --font-size-h2: 22px;
  }
}

@media (width <= 1200px) {
  :root {
    --font-size-h3: 22px;
  }
}

@media (width <= 1000px) {
  :root {
    --font-size-h3: 20px;
  }
}

@media (width <= 700px) {
  :root {
    --font-size-h3: 18px;
  }
}

@media (width <= 1200px) {
  :root {
    --font-size-h4: 18px;
  }
}

@media (width <= 1000px) {
  :root {
    --font-size-h4: 16px;
  }
}

@media (width <= 700px) {
  :root {
    --font-size-h4: 15px;
  }
}

.component-form {
  justify-content: center;
  padding: 3.5rem 0;
  display: flex;
}

.component-free-text + .component-form {
  padding-top: 1rem;
}

.component-form__inner {
  width: 80%;
  max-width: 1200px;
}

@media (width <= 1200px) {
  .component-form__inner {
    width: 85%;
  }
}

@media (width <= 1000px) {
  .component-form__inner {
    width: 90%;
  }
}

@media (width <= 700px) {
  .component-form__inner {
    width: calc(100% - 3rem);
  }
}

.component-columns .component-form__inner {
  width: 100%;
}

.component-form .nf-cell, .component-form .nf-multi-cell .nf-cell {
  padding: 0;
}

.component-form .nf-form-content {
  max-width: 100%;
  padding: 0;
}

.component-form .nf-form-content * {
  font-size: 1rem;
}

.component-form .nf-field-label label {
  text-transform: uppercase;
  font-weight: 700;
}

.component-form .nf-field-element input[type="text"], .component-form .nf-field-element input[type="tel"], .component-form .nf-field-element textarea, .component-form .nf-field-element input[type="email"] {
  background-color: var(--color-white);
  border-radius: var(--border-radius-xs);
  border-color: var(--color-blue-700);
  font-family: inherit;
  font-size: inherit;
}

.component-form .nf-field-element input[type="text"], .component-form .nf-field-element input[type="tel"], .component-form .nf-field-element textarea, .component-form .nf-field-element input[type="email"], .component-form .nf-field-element input[type="text"]:focus, .component-form .nf-field-element input[type="tel"]:focus, .component-form .nf-field-element textarea:focus, .component-form .nf-field-element input[type="email"]:focus {
  color: var(--color-text);
}

.component-form .listradio-wrap .nf-field-element label:after {
  background: var(--color-white);
  border: 1px solid var(--color-gray-500);
}

.component-form .listradio-wrap .nf-field-element label.nf-checked-label:before {
  background: var(--color-primary-accent);
  top: 5px;
}

.component-form nf-cells {
  flex-wrap: wrap;
  gap: 0 3rem;
  display: flex;
}

.component-form nf-cells .nf-cell {
  flex: 1;
  width: auto;
}

@media (width <= 600px) {
  .component-form nf-cells .nf-cell {
    width: 100%;
    min-width: 100%;
  }
}

input[type="number"] {
  -moz-appearance: textfield;
}

input::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

@media (width <= 1200px) {
  :root {
    --button-height: 46px;
  }
}

@media (width <= 1000px) {
  :root {
    --button-height: 40px;
  }
}

@media (width <= 700px) {
  :root {
    --button-height: 34px;
  }
}

@media (width <= 1200px) {
  :root {
    --dimensions-hero-height: 600px;
  }
}

@media (width <= 1000px) {
  :root {
    --dimensions-hero-height: 450px;
  }
}

@media (width <= 700px) {
  :root {
    --dimensions-hero-height: 350px;
  }
}

@media (width <= 1200px) {
  :root {
    --border-radius-xl: 60px;
  }
}

@media (width <= 1000px) {
  :root {
    --border-radius-xl: 40px;
    --border-radius-s: 12px;
    --border-radius-xs: 8px;
  }
}

@media (width <= 700px) {
  :root {
    --border-radius-xl: 25px;
    --border-radius-s: 8px;
  }
}

@media (width <= 1200px) {
  :root {
    --font-size-h1: 35px;
  }
}

@media (width <= 1000px) {
  :root {
    --font-size-h1: 30px;
  }
}

@media (width <= 700px) {
  :root {
    --font-size-h1: 26px;
  }
}

@media (width <= 1200px) {
  :root {
    --font-size-h2: 27px;
  }
}

@media (width <= 1000px) {
  :root {
    --font-size-h2: 25px;
  }
}

@media (width <= 700px) {
  :root {
    --font-size-h2: 22px;
  }
}

@media (width <= 1200px) {
  :root {
    --font-size-h3: 22px;
  }
}

@media (width <= 1000px) {
  :root {
    --font-size-h3: 20px;
  }
}

@media (width <= 700px) {
  :root {
    --font-size-h3: 18px;
  }
}

@media (width <= 1200px) {
  :root {
    --font-size-h4: 18px;
  }
}

@media (width <= 1000px) {
  :root {
    --font-size-h4: 16px;
  }
}

@media (width <= 700px) {
  :root {
    --font-size-h4: 15px;
  }
}

.component-hero__slides {
  overflow: hidden;
}

.component-hero__slide {
  min-height: var(--dimensions-hero-height);
  background: center / cover no-repeat;
  justify-content: center;
  display: flex;
  position: relative;
  overflow: hidden;
}

.component-hero__slide:after {
  content: " ";
  background: linear-gradient(var(--color-blue-700), #436772);
  mix-blend-mode: hard-light;
  opacity: .5;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.component-hero__slide-inner {
  align-items: flex-start;
  width: 80%;
  max-width: 1200px;
  display: flex;
}

@media (width <= 1200px) {
  .component-hero__slide-inner {
    width: 85%;
  }
}

@media (width <= 1000px) {
  .component-hero__slide-inner {
    width: 90%;
  }
}

@media (width <= 700px) {
  .component-hero__slide-inner {
    align-items: center;
    width: calc(100% - 3rem);
  }
}

.component-hero__slide-content {
  border-top-right-radius: var(--border-radius-xl);
  border-bottom-right-radius: var(--border-radius-xl);
  z-index: 1;
  background: var(--color-blue-700-transparent);
  --color-text-headline: var(--color-white);
  --color-text: var(--color-white);
  --color-button-background-primary: var(--color-white);
  --color-button-background-primary-hover: var(--color-blue-500);
  --color-button-text-primary: var(--color-gray-100);
  --color-button-text-primary-hover: var(--color-gray-100);
  max-width: 45%;
  margin-top: 7.5rem;
  padding: 3.125rem 3.125rem 3.125rem 0;
  position: relative;
}

@media (width <= 1200px) {
  .component-hero__slide-content {
    max-width: 50%;
    margin-top: 6rem;
    padding: 2.5rem 2.5rem 2.5rem 0;
  }
}

@media (width <= 1000px) {
  .component-hero__slide-content {
    max-width: 70%;
    margin-top: 4rem;
    padding: 2rem 2rem 2rem 0;
  }
}

@media (width <= 700px) {
  .component-hero__slide-content {
    max-width: 80%;
    margin-top: 0;
    margin-bottom: 2rem;
    padding: 1.5rem 1.5rem 1.5rem 0;
  }
}

.component-hero__slide-content p {
  font-size: var(--font-size-h3);
}

.component-hero__slide-content:after {
  content: " ";
  background-color: inherit;
  width: 100vw;
  height: 100%;
  position: absolute;
  top: 0;
  right: 100%;
}

.component-hero__slide-buttons {
  padding: 1rem 0 0;
}

.component-hero__dots {
  z-index: 1;
  width: 100%;
  height: 42px;
  position: absolute;
  transform: translateY(-100%);
}

.component-hero__dots .glider-dot {
  border: 2px solid var(--color-white);
  background: none;
}

.component-hero__dots .glider-dot.active {
  background: var(--color-white);
}

@media (width <= 1200px) {
  :root {
    --button-height: 46px;
  }
}

@media (width <= 1000px) {
  :root {
    --button-height: 40px;
  }
}

@media (width <= 700px) {
  :root {
    --button-height: 34px;
  }
}

@media (width <= 1200px) {
  :root {
    --dimensions-hero-height: 600px;
  }
}

@media (width <= 1000px) {
  :root {
    --dimensions-hero-height: 450px;
  }
}

@media (width <= 700px) {
  :root {
    --dimensions-hero-height: 350px;
  }
}

@media (width <= 1200px) {
  :root {
    --border-radius-xl: 60px;
  }
}

@media (width <= 1000px) {
  :root {
    --border-radius-xl: 40px;
    --border-radius-s: 12px;
    --border-radius-xs: 8px;
  }
}

@media (width <= 700px) {
  :root {
    --border-radius-xl: 25px;
    --border-radius-s: 8px;
  }
}

@media (width <= 1200px) {
  :root {
    --font-size-h1: 35px;
  }
}

@media (width <= 1000px) {
  :root {
    --font-size-h1: 30px;
  }
}

@media (width <= 700px) {
  :root {
    --font-size-h1: 26px;
  }
}

@media (width <= 1200px) {
  :root {
    --font-size-h2: 27px;
  }
}

@media (width <= 1000px) {
  :root {
    --font-size-h2: 25px;
  }
}

@media (width <= 700px) {
  :root {
    --font-size-h2: 22px;
  }
}

@media (width <= 1200px) {
  :root {
    --font-size-h3: 22px;
  }
}

@media (width <= 1000px) {
  :root {
    --font-size-h3: 20px;
  }
}

@media (width <= 700px) {
  :root {
    --font-size-h3: 18px;
  }
}

@media (width <= 1200px) {
  :root {
    --font-size-h4: 18px;
  }
}

@media (width <= 1000px) {
  :root {
    --font-size-h4: 16px;
  }
}

@media (width <= 700px) {
  :root {
    --font-size-h4: 15px;
  }
}

.component-image__inner {
  justify-content: var(--justify-content);
  flex-wrap: wrap;
  display: flex;
}

@media (width <= 700px) {
  .component-image__inner {
    justify-content: center;
  }
}

.component-image__inner img {
  max-width: 100%;
  height: auto;
}

@media (width <= 950px) {
  .component-image__inner img {
    margin-bottom: 1rem;
  }

  .component-image__inner img.component-image__image--native-size {
    max-width: 95%;
  }
}

.component-image__inner img.component-image__image--context-size {
  width: 73%;
  max-width: 75ch;
  height: auto;
}

@media (width <= 950px) {
  .component-image__inner img.component-image__image--context-size {
    width: 95%;
  }
}

.component-image + .component-free-text, .component-free-text + .component-image {
  margin: 2.5rem 0 0;
}

.component-image-list__content {
  padding: 0 0 2rem;
}

.component-image-list__images {
  counter-reset: image-list-counter;
  flex-wrap: wrap;
  justify-content: space-around;
  gap: 3.5rem;
  display: flex;
}

@media (width <= 500px) {
  .component-image-list__images {
    gap: 2.5rem 1.5rem;
  }
}

.component-image-list__image {
  flex-direction: column;
  flex: 1;
  align-items: center;
  min-width: 150px;
  max-width: 25%;
  display: flex;
}

@media (width <= 700px) {
  .component-image-list__image {
    min-width: 40%;
    max-width: none;
  }
}

@media (width <= 500px) {
  .component-image-list__image {
    min-width: 30%;
    max-width: 35%;
  }
}

.component-image-list__image img {
  max-width: 100%;
  max-height: 300px;
  margin: 0 0 1rem;
}

.component-image-list__image-wrapper {
  position: relative;
}

@media (width <= 500px) {
  .component-image-list__image-wrapper {
    max-width: 80%;
  }
}

.component-image-list__images--numbered .component-image-list__image-wrapper:before {
  content: counter(image-list-counter);
  counter-increment: image-list-counter;
  background-color: var(--color-primary-accent);
  color: var(--color-white);
  border-radius: 3rem;
  justify-content: center;
  align-items: center;
  width: 3rem;
  height: 3rem;
  font-size: 1.5rem;
  font-weight: 700;
  display: flex;
  position: absolute;
  top: -.7rem;
  left: -.7rem;
}

@media (width <= 900px) {
  .component-image-list__images--numbered .component-image-list__image-wrapper:before {
    width: 2.7rem;
    height: 2.7rem;
    font-size: 1.3rem;
    top: 0;
    left: 0;
  }
}

@media (width <= 500px) {
  .component-image-list__images--numbered .component-image-list__image-wrapper:before {
    width: 2.5rem;
    height: 2.5rem;
    font-size: 1.3rem;
    top: -.5rem;
    left: -.5rem;
  }
}

.component-image-list h1, .component-image-list h2, .component-image-list h3, .component-image-list p {
  max-width: 100%;
}

@media (width <= 1200px) {
  :root {
    --button-height: 46px;
  }
}

@media (width <= 1000px) {
  :root {
    --button-height: 40px;
  }
}

@media (width <= 700px) {
  :root {
    --button-height: 34px;
  }
}

@media (width <= 1200px) {
  :root {
    --dimensions-hero-height: 600px;
  }
}

@media (width <= 1000px) {
  :root {
    --dimensions-hero-height: 450px;
  }
}

@media (width <= 700px) {
  :root {
    --dimensions-hero-height: 350px;
  }
}

@media (width <= 1200px) {
  :root {
    --border-radius-xl: 60px;
  }
}

@media (width <= 1000px) {
  :root {
    --border-radius-xl: 40px;
    --border-radius-s: 12px;
    --border-radius-xs: 8px;
  }
}

@media (width <= 700px) {
  :root {
    --border-radius-xl: 25px;
    --border-radius-s: 8px;
  }
}

@media (width <= 1200px) {
  :root {
    --font-size-h1: 35px;
  }
}

@media (width <= 1000px) {
  :root {
    --font-size-h1: 30px;
  }
}

@media (width <= 700px) {
  :root {
    --font-size-h1: 26px;
  }
}

@media (width <= 1200px) {
  :root {
    --font-size-h2: 27px;
  }
}

@media (width <= 1000px) {
  :root {
    --font-size-h2: 25px;
  }
}

@media (width <= 700px) {
  :root {
    --font-size-h2: 22px;
  }
}

@media (width <= 1200px) {
  :root {
    --font-size-h3: 22px;
  }
}

@media (width <= 1000px) {
  :root {
    --font-size-h3: 20px;
  }
}

@media (width <= 700px) {
  :root {
    --font-size-h3: 18px;
  }
}

@media (width <= 1200px) {
  :root {
    --font-size-h4: 18px;
  }
}

@media (width <= 1000px) {
  :root {
    --font-size-h4: 16px;
  }
}

@media (width <= 700px) {
  :root {
    --font-size-h4: 15px;
  }
}

.component-news-list {
  justify-content: center;
  padding: 3.5rem 0;
  display: flex;
}

.component-news-list__inner {
  width: 80%;
  max-width: 1200px;
}

@media (width <= 1200px) {
  .component-news-list__inner {
    width: 85%;
  }
}

@media (width <= 1000px) {
  .component-news-list__inner {
    width: 90%;
  }
}

@media (width <= 700px) {
  .component-news-list__inner {
    width: calc(100% - 3rem);
  }
}

.component-news-list__post {
  flex-wrap: wrap;
  gap: 2rem;
  padding: 0 0 4rem;
  display: flex;
}

@media (width <= 500px) {
  .component-news-list__post {
    gap: 1rem;
  }
}

.component-news-list__content-above {
  padding: 0 0 .5rem;
}

.component-news-list__post-image, .component-news-list__post-text {
  flex: 1;
}

@media (width <= 500px) {
  .component-news-list__post-image, .component-news-list__post-text {
    min-width: 100%;
  }
}

.component-news-list__post-image img {
  max-width: 100%;
  height: auto;
  display: block;
}

.component-news-list__post-text {
  padding: .4rem 0;
}

@media (width <= 830px) {
  .component-news-list__post-text {
    flex: 1.5;
  }
}

.component-news-list__post-date {
  color: var(--color-primary-accent);
}

.component-news-list__read-more-link {
  margin: 2rem 0 0;
}

.page-numbers {
  padding: 0 .1rem;
  font-weight: 700;
}

.page-numbers.current {
  text-decoration: underline;
}

.component-pop-out-image {
  justify-content: var(--justify-content);
  pointer-events: none;
  width: 95%;
  margin: 0 auto;
  display: flex;
  position: absolute;
  left: 2.5%;
}

@media (width <= 700px) {
  .component-pop-out-image {
    display: none;
  }
}

.component-pop-out-image__image {
  transform: translateY(-50%);
}

.component-pop-out-image__image--half {
  max-width: 50%;
}

.component-pop-out-image__image--one-third, .component-pop-out-image__image--two-thirds {
  max-width: 33.3333%;
}

.privat-foretag-choice {
  height: calc(100vh - 43px);
  max-height: 950px;
  display: flex;
  position: relative;
}

@media (width <= 815px) {
  .privat-foretag-choice {
    flex-direction: column;
  }
}

.privat-foretag-choice__column {
  background: var(--ssf-light-blue);
  flex: 1;
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;
}

.privat-foretag-choice__column + .privat-foretag-choice__column {
  background: var(--color-primary-accent);
}

.privat-foretag-choice__column + .privat-foretag-choice__column * {
  color: var(--color-white);
}

@media (width <= 815px) {
  .privat-foretag-choice__column + .privat-foretag-choice__column {
    padding-top: 3rem;
  }
}

.privat-foretag-choice__column-inner {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 645px;
  padding: 1rem 2rem 3rem;
  display: flex;
}

@media (width <= 815px) {
  .privat-foretag-choice__column-inner {
    min-height: 50vh;
  }
}

@media (width <= 600px) {
  .privat-foretag-choice__column-inner {
    min-height: 60vh;
    padding-bottom: 4rem;
  }
}

.privat-foretag-choice__center-image {
  position: absolute;
  top: 25%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@media (width <= 815px) {
  .privat-foretag-choice__center-image {
    top: 50%;
  }
}

.privat-foretag-choice__center-image img {
  object-fit: contain;
  object-position: center;
  width: 165px;
  height: 180px;
}

@media (width <= 815px) {
  .privat-foretag-choice__center-image img {
    width: 150px;
    height: 150px;
  }
}

.privat-foretag-choice__logo img {
  object-fit: contain;
  object-position: center;
  width: 230px;
  height: 100px;
  position: absolute;
  bottom: 4rem;
  left: 50%;
  transform: translateX(-50%);
}

@media (width <= 815px) {
  .privat-foretag-choice__logo img {
    width: 150px;
    height: 80px;
    bottom: 3rem;
  }
}

:root {
  --color-gray-900: #f2f2f2;
  --color-gray-800: #eae8e8;
  --color-gray-700: #bbb;
  --color-gray-600: #999;
  --color-gray-500: #707070;
  --color-gray-100: #000;
  --color-blue-700: #85cee4;
  --color-blue-700-transparent: #85cee4cc;
  --color-blue-500: #009baf;
  --color-blue-300: #048798;
  --color-blue-200: #007584;
  --color-purple-700: #e1c1de;
  --color-purple-500: #8b5e8f;
  --color-green-700: #99c4c3;
  --color-green-500: #1da195;
  --color-pink-500: #d67397;
  --color-pink-300: #a80053;
  --color-beige-700: #fde1c6;
  --color-white: #fff;
  --ssf-primary: #023953;
  --ssf-secondary: #e1197c;
  --ssf-light-blue: #dff2f7;
  --ssf-blue-transparent: #023953cc;
  --color-link: var(--color-blue-500);
  --color-footer-background: var(--color-blue-500);
  --color-footer-text: var(--color-white);
  --color-text: var(--color-gray-100);
  --color-text-headline: var(--color-gray-100);
  --color-primary-accent: var(--color-blue-500);
  --color-button-background-primary: var(--color-primary-accent);
  --color-button-background-primary-hover: var(--color-blue-700);
  --color-button-text-primary: var(--color-white);
  --color-button-text-primary-hover: var(--color-white);
  --border-button-primary: 1px solid transparent;
  --color-button-background-secondary: var(--color-purple-500);
  --color-button-background-secondary-hover: var(--color-purple-700);
  --color-button-text-secondary: var(--color-white);
  --color-button-text-secondary-hover: var(--color-white);
  --border-button-secondary: 1px solid transparent;
  --color-button-background-tertiary: transparent;
  --color-button-background-tertiary-hover: var(--color-white);
  --color-button-text-tertiary: var(--color-gray-100);
  --color-button-text-tertiary-hover: var(--color-gray-100);
  --border-button-tertiary: 1px solid var(--color-gray-100);
  --color-dropdown-text: var(--color-white);
  --color-dropdown-background: var(--color-blue-300);
  --color-dropdown-background-hover: var(--color-blue-200);
  --button-height: 50px;
  --dimensions-button-height: var(--button-height);
  --dimensions-hero-height: 645px;
  --border-radius-xl: 75px;
  --border-radius-s: 14px;
  --border-radius-xs: 11px;
  --font-size-h1: 40px;
  --font-size-h2: 30px;
  --font-size-h3: 25px;
  --font-size-h4: 20px;
}

@media (width <= 1200px) {
  :root {
    --button-height: 46px;
  }
}

@media (width <= 1000px) {
  :root {
    --button-height: 40px;
  }
}

@media (width <= 700px) {
  :root {
    --button-height: 34px;
  }
}

@media (width <= 1200px) {
  :root {
    --dimensions-hero-height: 600px;
  }
}

@media (width <= 1000px) {
  :root {
    --dimensions-hero-height: 450px;
  }
}

@media (width <= 700px) {
  :root {
    --dimensions-hero-height: 350px;
  }
}

@media (width <= 1200px) {
  :root {
    --border-radius-xl: 60px;
  }
}

@media (width <= 1000px) {
  :root {
    --border-radius-xl: 40px;
    --border-radius-s: 12px;
    --border-radius-xs: 8px;
  }
}

@media (width <= 700px) {
  :root {
    --border-radius-xl: 25px;
    --border-radius-s: 8px;
  }
}

@media (width <= 1200px) {
  :root {
    --font-size-h1: 35px;
  }
}

@media (width <= 1000px) {
  :root {
    --font-size-h1: 30px;
  }
}

@media (width <= 700px) {
  :root {
    --font-size-h1: 26px;
  }
}

@media (width <= 1200px) {
  :root {
    --font-size-h2: 27px;
  }
}

@media (width <= 1000px) {
  :root {
    --font-size-h2: 25px;
  }
}

@media (width <= 700px) {
  :root {
    --font-size-h2: 22px;
  }
}

@media (width <= 1200px) {
  :root {
    --font-size-h3: 22px;
  }
}

@media (width <= 1000px) {
  :root {
    --font-size-h3: 20px;
  }
}

@media (width <= 700px) {
  :root {
    --font-size-h3: 18px;
  }
}

@media (width <= 1200px) {
  :root {
    --font-size-h4: 18px;
  }
}

@media (width <= 1000px) {
  :root {
    --font-size-h4: 16px;
  }
}

@media (width <= 700px) {
  :root {
    --font-size-h4: 15px;
  }
}

.body--privat {
  --color-primary-accent: var(--ssf-primary);
  --color-button-background-primary: var(--ssf-secondary);
  --color-dropdown-background: var(--ssf-light-blue);
  --color-dropdown-background-hover: var(--ssf-light-blue);
  --color-dropdown-text: var(--ssf-primary);
  --color-link: var(--ssf-secondary);
  --color-footer-background: var(--ssf-primary);
  --color-blue-700-transparent: var(--ssf-blue-transparent);
  --color-button-background-primary-hover: var(--ssf-secondary);
}

.component-video {
  justify-content: center;
  padding: 3.5rem 0;
  display: flex;
}

.component-video__inner {
  width: 80%;
  max-width: 1200px;
}

@media (width <= 1200px) {
  .component-video__inner {
    width: 85%;
  }
}

@media (width <= 1000px) {
  .component-video__inner {
    width: 90%;
  }
}

@media (width <= 700px) {
  .component-video__inner {
    width: calc(100% - 3rem);
  }
}

.component-video__video {
  width: 100%;
  padding-top: 56.25%;
  position: relative;
  overflow: hidden;
}

.component-video__video--16_9 {
  padding-top: 56.25%;
}

.component-video__video--4_3 {
  padding-top: 75%;
}

.component-video__video iframe {
  background: #000;
  width: 100%;
  height: 100%;
  position: absolute;
  inset: 0;
}
/*# sourceMappingURL=main.css.map */
